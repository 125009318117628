.playlist {
  .image_container {
    display: flex;
    .image-img {
      width: 250px;
      height: 250px;
    }
    .image_details {
      margin-left: 20px;
      .title-name {
        margin: 0;
      }
      .author-name {
        color: #929191;
        font-size: 20px;
        font-weight: 600;
        margin-bottom: 5px;
      }
      .playlist-button {
        background-color: rgb(222, 88, 88);
        color: #fff;
        border-radius: 25px;
        outline: none;
        border: none;
        padding: 5px 20px;
        font-size: 18px;
      }
    }
  }
  .playlist_container {
    margin-top: 5%;
    .playlist_1 {
      display: flex;
      border-bottom: 1px solid #dddddd;
      margin-bottom: 15px;
      justify-content: space-between;
      cursor: pointer;
      div {
        display: flex;
        img {
          width: 50px;
          height: 50px;
          margin-left: 5px;
        }
        .playlist_details {
          margin-left: 5px;
          flex-direction: column;
        }
      }
    }
  }
}
@media (max-width: 500px) {
  .playlist {
    .image_container {
      .image-img {
        width: 150px;
        height: 150px;
      }
    }
  }
}
