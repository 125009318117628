@font-face {
  font-family: "futuraMediumBT";
  src: url("../fonts/futura/futuraMediumBT.eot");
  src: local("futuraMediumBT"),
    url("../fonts/futura/futuraMediumBT.woff") format("woff"),
    url("../fonts/futura/futuraMediumBT.ttf") format("truetype");
}
@import url('https://fonts.googleapis.com/css2?family=Mulish:wght@300;400;500;600;700&family=Quicksand:wght@400;500;600;700&display=swap');


/* @font-face {
  font-family: 'myriad_proregular';
  src: url('myriadpro-regular-webfont.woff2') format('woff2'),
       url('myriadpro-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

} */
body {
  /* --active-color: #f57700 hsl(29deg 100% 48%); dark primary base color
  --top-bar-color-text: #fb6200 hsl(23deg 100% 49%);
  --top-bar-color-light: #ffddbf hsl(28deg 100% 87%);
  --dark-active-color: #f83600 hsl(13deg 100% 49%);
  --orange-color: #fe8c00 hsl(33deg 100% 50%);
  --color-1: #fb5e00 hsl(22deg 100% 49%); dark
  --color-3: #fd8900 hsl(32deg 100% 50%); dark
  --color-4: #ff8240 hsl(21deg 100% 63%); dark
  --color-5: #ffeddf hsl(26deg 100% 94%); light
  --color-6: #fffaf6 hsl(27deg 100% 98%); light   base color
  --color-7: #af6a6a hsl(0deg 30% 55%); dark
  --color-8: #ffd9b8 hsl(28deg 100% 86%); light
  --color-9: #d8bec4 hsl(346deg 25% 80%); light
  --color-10: #ffe4cb hsl(29deg 100% 90%); light
  --color-11: #de4200 hsl(18deg 100% 44%); dark
  --color-12: #df5100 hsl(22deg 100% 44%); dark
  --color-14: #fc6800 hsl(25deg 100% 49%); dark
  --color-15: #fff9f5 hsl(24deg 100% 98%); light
  --color-18: #f94f20 hsl(13deg 95% 55%); dark
  --color-20: #f8d5b2 hsl(30deg 83% 84%); light
  --color-21: #e7c9b9 hsl(21deg 49% 82%); light
  --color-22 : #ffe2d1 hsl(22deg 100% 91%); light
  --color-23 : #e35800 hsl(23deg 100% 45%); dark
  --color-24: #fff1e4 hsl(29deg 100% 95%); light
  --color-25: #ffecdd hsl(26deg 100% 93%); light
  --color-26: #bfab99 hsl(28deg 23% 67%); dark
  --color-27: #ffe2d2 hsl(21deg 100% 91%); light
  --color-28: #f7d5b0 hsl(31deg 82% 83%); light
  --btn-primary : #d62f00 hsl(13deg 100% 42%); dark */

  --hue: 29;
  --saturation: 100%;
  --light: 48%;

  --secondary-hue: 27;
  --secondary-saturation: 100%;
  --secondary-light: 98%;
}

$hue: var(--hue);
$saturation: var(--saturation);
$light: var(--light);

$secondary-hue: var(--secondary-hue);
$secondary-saturation: var(--secondary-saturation);
$secondary-light: var(--secondary-light);

$active-color: hsl(var(--hue), var(--saturation), calc(var(--light)))
  /* var(--active-color) */;
$dark-active-color: hsl(
    calc(var(--hue) - 16),
    calc(var(--saturation)),
    calc(var(--light) + 1%)
  )
  /* #f83600 */;
$orange-color: hsl(
    calc(var(--hue) + 4),
    calc(var(--saturation)),
    calc(var(--light) + 2%)
  )
  /* #fe8c00 */;
$orange-color-opacity: hsla(
  calc(var(--hue) + 4),
  calc(var(--saturation)),
  calc(var(--light) + 2%),
  80%
);
$white-color: #fff;
$top-bar-color-light: hsl(
  calc(var(--secondary-hue) + 1),
  calc(var(--secondary-saturation)),
  calc(var(--secondary-light) - 9%)
); /* var(--top-bar-color-light); */
$top-bar-color-text: hsl(
    calc(var(--hue) - 6),
    calc(var(--saturation)),
    calc(var(--light) + 1%)
  )
  /* #fb6200 */;
$color-1: hsl(
    calc(var(--hue) - 7),
    calc(var(--saturation)),
    calc(var(--light) + 1%)
  )
  /* #fb5e00 */;
$color-2: #707070;
$color-3: hsl(
    calc(var(--hue) + 3),
    calc(var(--saturation)),
    calc(var(--light) + 2%)
  )
  /* #fd8900 */;
$color-4: hsl(
    calc(var(--hue) - 8),
    calc(var(--saturation)),
    calc(var(--light) + 15%)
  )
  /* #ff8240 */;
$color-5: hsl(
    calc(var(--secondary-hue) - 1),
    calc(var(--secondary-saturation)),
    calc(var(--secondary-light) - 4%)
  )
  /* #ffeddf */;
/* $color-6: hsl(
    calc(var(--hue) - 2),
    calc(var(--saturation)),
    calc(var(--light) + 50%)
  ) */
$color-6: hsl(
    var(--secondary-hue),
    var(--secondary-saturation),
    calc(var(--secondary-light))
  )
  /* #fffaf6 */;
$color-7: hsl(
    calc(var(--hue) - 29),
    calc(var(--saturation) - 70%),
    calc(var(--light) + 7%)
  )
  /* #af6a6a */;
/* $color-8: hsl(
    calc(var(--hue) - 1),
    calc(var(--saturation)),
    calc(var(--light) + 38%)
  ) */
$color-8: hsl(
    calc(var(--secondary-hue) - 1),
    calc(var(--secondary-saturation)),
    calc(var(--secondary-light) - 12%)
  )
  /* #ffd9b8 */;
/* $color-9: hsl(
    calc(var(--hue) + 317),
    calc(var(--saturation) - 75%),
    calc(var(--light) + 32%)
  ) */
$color-9: hsl(
    calc(var(--secondary-hue) + 319),
    calc(var(--secondary-saturation) - 75%),
    calc(var(--secondary-light) - 18%)
  )
  /* #d8bec4 */;
/* $color-10: hsl(
    calc(var(--hue)),
    calc(var(--saturation)),
    calc(var(--light) + 42%)
  ) */
$color-10: hsl(
    calc(var(--secondary-hue) + 2),
    calc(var(--secondary-saturation)),
    calc(var(--secondary-light) - 8%)
  )
  /* #ffe4cb */;
$color-11: hsl(
    calc(var(--hue) - 11),
    calc(var(--saturation)),
    calc(var(--light) - 4%)
  )
  /* #de4200 */;
$color-12: hsl(
    calc(var(--hue) - 7),
    calc(var(--saturation)),
    calc(var(--light) - 4%)
  )
  /* #df5100 */;
/* $color-13: #f83600; */
$color-14: hsl(
    calc(var(--hue) - 4),
    calc(var(--saturation)),
    calc(var(--light) + 1%)
  )
  /* #fc6800 */;
/* $color-15: hsl(
    calc(var(--hue) - 5),
    calc(var(--saturation)),
    calc(var(--light) + 50%)
  ) */
$color-15: hsl(
    calc(var(--secondary-hue) - 3),
    calc(var(--secondary-saturation)),
    calc(var(--secondary-light))
  )
  /* #fff9f5 */;
$color-16: #717171;
$color-17: #565656;
$color-18: hsl(
  calc(var(--hue) - 16),
  calc(var(--saturation) - 5%),
  calc(var(--light) + 7%)
);
$color-19: #8a8a8a;
$color-20: hsl(
    calc(var(--secondary-hue) + 3),
    calc(var(--secondary-saturation) - 17%),
    calc(var(--secondary-light) - 14%)
  )
  /* #f8d5b2 */;
$color-21: hsl(
    calc(var(--secondary-hue) - 6),
    calc(var(--secondary-saturation) - 51%),
    calc(var(--secondary-light) - 16%)
  )
  /* #e7c9b9 */;
$color-22: hsl(
    calc(var(--secondary-hue) - 5),
    calc(var(--secondary-saturation)),
    calc(var(--secondary-light) - 7%)
  )
  /* #ffe2d1 */;
$color-23: hsl(
    calc(var(--hue) - 6),
    calc(var(--saturation)),
    calc(var(--light) - 6%)
  )
  /* #e35800 */;
$color-24: hsl(
    calc(var(--secondary-hue) + 2),
    calc(var(--secondary-saturation)),
    calc(var(--secondary-light) - 3%)
  )
  /* #fff1e4 */;
$color-25: hsl(
    calc(var(--secondary-hue) - 1),
    calc(var(--secondary-saturation)),
    calc(var(--secondary-light) - 5%)
  )
  /* #ffecdd */;

$color-26: hsl(
    calc(var(--hue) - 1),
    calc(var(--saturation) - 77%),
    calc(var(--light) + 19%)
  )
  /* #bfab99 */;

$color-27: hsl(
    calc(var(--secondary-hue) - 6),
    calc(var(--secondary-saturation)),
    calc(var(--secondary-light) - 7%)
  )
  /* #ffe2d2 */;

$color-28: hsl(
    calc(var(--secondary-hue) + 4),
    calc(var(--secondary-saturation) - 18%),
    calc(var(--secondary-light) - 15%)
  )
  /* #f7d5b0 */;

$btn-primary: hsl(
    calc(var(--hue) - 16),
    calc(var(--saturation)),
    calc(var(--light) - 6%)
  )
  /* #d62f00 */;
$orange-linear: linear-gradient(
  180deg,
  $orange-color 0%,
  $dark-active-color 160.03%
);
$header-width: 67%;
$header-shadow: 0px 2px 2px #a64100;
$header-spacing: 7px 0 7px 0px;
$sgadi-logo-shadow: 0 8px 6px -6px rgb(0 0 0 / 15%);
$btn-box-shadow: 0px 4px 14px 1px rgba(0, 0, 0, 0.16);

$normal-color: #5c5e62;
$black-color: #1f2933;
$black-color-pure: #000;
$black-color-05-opacity: rgb(31 41 51 / 50%);
$footer-color: #3b434c;
$footer-color-hover: #5d6167;
$footer-copy-right-color: #adaeaf;
$background-color-2: #f5f7fa;
$active-color-2: #b0a377;
$grey-color: rgb(97 110 124);
$grey-color-05-opacity: rgb(97 110 124 / 50%);
$grey-color-05-opacity-2: rgba(48, 48, 48, 0.5);

$font-family-poppins: "Poppins", sans-serif;
$font-family-lato: Lato;
$font-family-roboto: "Roboto", sans-serif;
$font-family-open-sans: "Open Sans", sans-serif;
$font-family-roboto-slab: "Roboto Slab", serif;
$font-family-futura: "PT Sans", sans-serif;
$font-family-futura-bt: "futuraMediumBT";
$font-family-noto-sans-devnagari: "Noto Sans Devanagari", sans-serif;
$font-family-gujarati : "Hind Vadodara", sans-serif !important;

$font-family-quicksand-title : 'Quicksand', sans-serif;
$font-family-mulish-text : 'Mulish', sans-serif;

$font-family-quicksand : 'Quicksand', sans-serif;
$font-family-mulish : 'Mulish', sans-serif;

$text-transform-site: capitalize;
$font-weight-normal: 400;
/* $your_color: #db4e29;
$another_color: #92e4bd;
$colors : mix($your_color, $another_color, 20%);
div {
  background: $colors;
} */
body{
  background-color: $color-15;
}
label {
  &.required {
    &::after {
      content: " *";
      color: #ff0000;
    }
  }
}
.align-items-center {
  align-items: center;
}
.grey-color-05-opacity-bg {
  background: $grey-color-05-opacity;
}
.grey-color-05-opacity-bg-2 {
  background: $grey-color-05-opacity-2;
}
.black-color-bg {
  background: $black-color-pure;
}
/*Page animation start*/
.fade-appear,
.fade-enter {
  opacity: 0;
}
.fade-appear-active,
.fade-enter-active {
  transition: opacity 0.3s linear;
  opacity: 1;
}
.fade-exit {
  transition: opacity 0.2s linear;
  opacity: 1;
}
.fade-exit-active {
  opacity: 0;
}
/*Page animation end*/
.font-weight-600{
  font-weight: 600;
}
.font-family-noto-sans-devnagari {
  font-family: $font-family-noto-sans-devnagari;
}
.font-family-futura-bt {
  font-family: $font-family-futura-bt !important;
}
.font-family-roboto-slab {
  font-family: $font-family-roboto-slab;
}
.font-family-roboto {
  font-family: $font-family-roboto;
}
.font-family-gujarati {
  font-family: $font-family-gujarati;
}
.font-family-quicksand-title{
  font-family: $font-family-quicksand-title;
}
.font-family-mulish{
  font-family: $font-family-mulish
}
.highLightText {
  padding: 0;
  font-weight: 600;
  background-color: #f0e930;
  color: $black-color-pure;
}
.min-h-2 {
  min-height: 2px !important;
}
@mixin Flower-top {
  .Flower-top {
    position: absolute;
    right: 0;
    width: 12vw;
    z-index: 0;
  }
}

@mixin play-pause-button {
  cursor: pointer;
  background-color: $color-18;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  box-shadow: 0 3px 3px 0 rgb(0 0 0 / 24%), 0 0 3px 0 rgb(0 0 0 / 12%);
  svg {
    fill: #fff;
  }
}

@mixin border-with-bg-radius(
  $border-color: $color-20,
  $bg: $white-color,
  $border-radius: 15px
) {
  background: $bg;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  backface-visibility: hidden;
}

@mixin font-size-38 {
  $font-size-38: 38px;
  font-size: $font-size-38;
  @media (max-width: 1600px) {
    font-size: calc($font-size-38 - 6px);
  }
  @media (max-width: 1366px) {
    font-size: calc($font-size-38 - 10px);
  }
  @media (max-width: 1200px) {
    font-size: calc($font-size-38 - 13px);
  }
}
@mixin font-size-37 {
  $font-size-37: 37px;
  font-size: $font-size-37;
  @media (max-width: 1600px) {
    font-size: calc($font-size-37 - 6px);
  }
  @media (max-width: 1366px) {
    font-size: calc($font-size-37 - 10px);
  }
  @media (max-width: 1200px) {
    font-size: calc($font-size-37 - 13px);
  }
}
@mixin font-size-36 {
  $font-size-36: 36px;
  font-size: $font-size-36;
  @media (max-width: 1600px) {
    font-size: calc($font-size-36 - 6px);
  }
  @media (max-width: 1366px) {
    font-size: calc($font-size-36 - 8px);
  }
  @media (max-width: 1200px) {
    font-size: calc($font-size-36 - 12px);
  }
  @media (max-width: 768px) {
    /* font-size: calc($font-size-36 - 12px); */
  }
}
@mixin font-size-34 {
  $font-size-34: 34px;
  font-size: $font-size-34;
  @media (max-width: 1366px) {
    font-size: calc($font-size-34 - 4px);
  }
  @media (max-width: 1200px) {
    font-size: calc($font-size-34 - 6px);
  }
  @media (max-width: 1024px) {
    font-size: calc($font-size-34 - 9px);
  }
  @media (max-width: 991px) {
    font-size: calc($font-size-34 - 8px);
  }
}
@mixin font-size-30 {
  $font-size-30: 30px;
  font-size: $font-size-30;
  @media (max-width: 1600px) {
    font-size: calc($font-size-30 - 4px);
  }
  @media (max-width: 1366px) {
    font-size: calc($font-size-30 - 8px);
  }
}
@mixin banner-title-font-size {
  $font-size-banner: 22px;
  font-size: $font-size-banner;
  @media (max-width: 1600px) {
    font-size: calc($font-size-banner - 4px);
  }
  @media (max-width: 1366px) {
    font-size: calc($font-size-banner - 8px);
  }
  @media (max-width: 991px) {
    font-size: calc($font-size-banner - 10px);
  }
  /* @media (max-width: 575px) {
    font-size: calc($font-size-banner - 18px);
  } */
}
@mixin font-size-26 {
  $font-size-26: 26px;
  font-size: $font-size-26;
  @media (max-width: 1600px) {
    font-size: calc($font-size-26 - 4px);
  }
  @media (max-width: 1366px) {
    font-size: calc($font-size-26 - 8px);
  }
}
@mixin font-size-24 {
  $font-size-24: 24px;
  font-size: $font-size-24;
  @media (max-width: 1800px) {
  }
  @media (max-width: 1700px) {
  }
  @media (max-width: 1600px) {
    font-size: calc($font-size-24 - 4px);
  }
  @media (max-width: 1366px) {
    font-size: calc($font-size-24 - 8px);
  }
  @media (max-width: 1300px) {
  }
  @media (max-width: 1200px) {
    font-size: calc($font-size-24 - 9px);
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 991px) {
    /* font-size: calc($font-size-24 - 4px); */
  }
  @media (max-width: 768px) {
    /* font-size: calc($font-size-24 - 10px); */
  }
  @media (max-width: 575px) {
  }
  @media (max-width: 500px) {
  }
}
@mixin font-size-24-v1 {
  $font-size-24-v1: 24px;
  font-size: $font-size-24-v1;
  @media (max-width: 1600px) {
    font-size: calc($font-size-24-v1 - 2px);
  }
  @media (max-width: 1366px) {
    font-size: calc($font-size-24-v1 - 4px);
  }
  @media (max-width: 1200px) {
    font-size: calc($font-size-24-v1 - 6px);
  }
}
@mixin font-size-22 {
  $font-size-22: 22px;
  font-size: $font-size-22;
  @media (max-width: 1800px) {
  }
  @media (max-width: 1700px) {
  }
  @media (max-width: 1600px) {
    font-size: calc($font-size-22 - 4px);
  }
  @media (max-width: 1366px) {
    font-size: calc($font-size-22 - 6px);
  }
  @media (max-width: 1300px) {
  }
  @media (max-width: 1200px) {
    font-size: calc($font-size-22 - 7px);
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 991px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 575px) {
  }
  @media (max-width: 500px) {
  }
}
@mixin font-size-22-v1 {
  $font-size-22-v1: 22px;
  font-size: $font-size-22-v1;
  @media (max-width: 1600px) {
    font-size: calc($font-size-22-v1 - 2px);
  }
  @media (max-width: 1366px) {
    font-size: calc($font-size-22-v1 - 4px);
  }
  @media (max-width: 1200px) {
    font-size: calc($font-size-22-v1 - 6px);
  }
  @media (max-width: 768px) {
    font-size: calc($font-size-22-v1 - 8px);
  }
}
@mixin font-size-20 {
  $font-size-20: 20px;
  font-size: $font-size-20;
  @media (max-width: 1800px) {
  }
  @media (max-width: 1700px) {
  }
  @media (max-width: 1600px) {
    font-size: calc($font-size-20 - 2px);
  }
  @media (max-width: 1366px) {
    font-size: calc($font-size-20 - 4px);
  }
  @media (max-width: 1300px) {
  }
  @media (max-width: 1200px) {
    font-size: calc($font-size-20 - 5px);
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 991px) {
    /* font-size: calc($font-size-20 - 4px); */
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 575px) {
  }
  @media (max-width: 500px) {
  }
}
@mixin font-size-20-v1 {
  $font-size-20-v1: 20px;
  font-size: $font-size-20-v1;
  @media (max-width: 1366px) {
    font-size: calc($font-size-20-v1 - 2px);
  }
  @media (max-width: 1200px) {
    font-size: calc($font-size-20-v1 - 4px);
  }
}
@mixin font-size-20-v2 {
  $font-size-20-v2: 20px;
  font-size: $font-size-20-v2;
  @media (max-width: 1366px) {
    font-size: calc($font-size-20-v2 - 4px);
  }
  @media (max-width: 1200px) {
    font-size: calc($font-size-20-v2 - 6px);
  }
}
@mixin font-size-18 {
  $font-size-18: 18px;
  font-size: $font-size-18;
  @media (max-width: 1600px) {
    font-size: calc($font-size-18 - 1px);
  }
  @media (max-width: 1366px) {
    font-size: calc($font-size-18 - 2px);
  }
  @media (max-width: 1200px) {
    font-size: calc($font-size-18 - 4px);
  }
}
@mixin font-size-18-v1 {
  $font-size-18-v1: 18px;
  font-size: $font-size-18-v1;
  @media (max-width: 1600px) {
    font-size: calc($font-size-18-v1 - 4px);
  }
  @media (max-width: 1366px) {
    font-size: calc($font-size-18-v1 - 5px);
  }
}
@mixin font-size-18-v2 {
  $font-size-18-v2: 18px;
  font-size: $font-size-18-v2;
  @media (max-width: 1600px) {
    font-size: calc($font-size-18-v2 - 2px);
  }
  @media (max-width: 1366px) {
    font-size: calc($font-size-18-v2 - 4px);
  }
  @media (max-width: 1200px) {
    font-size: calc($font-size-18-v2 - 6px);
  }
}
@mixin font-size-18-v3 {
  $font-size-18-v2: 18px;
  font-size: $font-size-18-v2;
  @media (max-width: 1600px) {
    font-size: calc($font-size-18-v2 - 2px);
  }
  @media (max-width: 1600px) {
    font-size: calc($font-size-18-v2 - 3px);
  }
  @media (max-width: 1366px) {
    font-size: calc($font-size-18-v2 - 4px);
  }
  @media (max-width: 1200px) {
    font-size: calc($font-size-18-v2 - 6px);
  }
}
@mixin font-size-17 {
  $font-size-17: 17px;
  font-size: $font-size-17;
  @media (max-width: 1366px) {
    font-size: calc($font-size-17 - 1px);
  }
  @media (max-width: 1200px) {
    font-size: calc($font-size-17 - 3px);
  }
}
@mixin font-size-17-v2 {
  $font-size-17-v2: 17px;
  font-size: $font-size-17-v2;
  @media (max-width: 1600px) {
    font-size: calc($font-size-17-v2 - 3px);
  }
  @media (max-width: 1366px) {
    font-size: calc($font-size-17-v2 - 4px);
  }
}
@mixin font-size-16 {
  $font-size-16: 16px;
  font-size: $font-size-16;
  @media (max-width: 1600px) {
    font-size: calc($font-size-16 - 2px);
  }
  @media (max-width: 1366px) {
    font-size: calc($font-size-16 - 3px);
  }
  @media (max-width: 768px) {
    font-size: calc($font-size-16 - 4px);
  }
}
@mixin font-size-14 {
  $font-size-14: 14px;
  font-size: $font-size-14;
  @media (max-width: 1600px) {
    font-size: calc($font-size-14 - 1px);
  }
}

@mixin font-size-13 {
  $font-size-13: 13px;
  font-size: $font-size-13;
  @media (max-width: 1600px) {
    font-size: calc($font-size-13 - 1px);
  }
}

@mixin font-size-10 {
  $font-size-10: 10px;
  font-size: $font-size-10;
  @media (max-width: 1800px) {
  }
  @media (max-width: 1700px) {
  }
  @media (max-width: 1600px) {
    font-size: calc($font-size-10 - 2px);
  }
  @media (max-width: 1366px) {
    font-size: calc($font-size-10 - 3px);
  }
  /* 
  @media (max-width: 1300px) {
  }
  @media (max-width: 1200px) {
    font-size: calc($font-size-10 - 7px);
  }
  @media (max-width: 1024px) {
  }
  @media (max-width: 991px) {
  }
  @media (max-width: 768px) {
  }
  @media (max-width: 575px) {
  }
  @media (max-width: 500px) {
  } */
}

@mixin line-height-26 {
  $line-height-26: 26px;
  line-height: $line-height-26;
  @media (max-width: 1600px) {
    line-height: calc($line-height-26 - 1px);
  }
  @media (max-width: 1366px) {
    line-height: calc($line-height-26 - 2px);
  }
  @media (max-width: 1200px) {
    line-height: calc($line-height-26 - 4px);
  }
}

.font-size-30px {
  @include font-size-30();
}
.font-size-22px {
  @include font-size-22();
}
.font-size-24px {
  @include font-size-24();
}
.font-size-18px {
  @include font-size-18();
}
.font-size-16px {
  @include font-size-16();
}
.font-size-14px {
  @include font-size-14();
}
.font-size-13px {
  @include font-size-13();
}
.border-with-bg-radius {
  @include border-with-bg-radius();
}
.gap-40{
  gap: 40px;
}
.color-black {
  color: $black-color !important;
}
.color-white {
  color: $white-color !important;
}
.color-grey {
  color: $normal-color !important;
}
.cursor-pointer {
  cursor: pointer;
}
.width-fit-content{
  width: fit-content;
}
@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.bg-off-white {
  background-color: $color-6;
}
.bg-white {
  background-color: $white-color !important;
}
body {
  font-family: $font-family-mulish-text/* $font-family-roboto */;
  color: #444444;
  padding: 93px 0 0 0 /* 105px 0 0 0 */;
  letter-spacing: normal;
  text-align: start;
}
a {
  color: $active-color;
  text-decoration: none;
  transition: 0.2s all;
  &:hover {
    color: $dark-active-color;
    text-decoration: none;
  }
}
h1 {
  font-family: $font-family-quicksand-title/* $font-family-roboto */;
}
h2 {
  font-family: $font-family-quicksand-title/* $font-family-roboto */;
}
h3 {
  font-family: $font-family-quicksand-title/* $font-family-roboto */;
}
h4 {
  font-family: $font-family-quicksand-title/* $font-family-roboto */;
}
h5 {
  font-family: $font-family-quicksand-title/* $font-family-roboto */;
}
h6 {
  font-family: $font-family-quicksand-title/* $font-family-roboto */;
}
img {
  backface-visibility: hidden;
}
@keyframes pulse {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(1.1);
  }
  100% {
      transform: scale(1);
  }
}
.pulse{
  animation: pulse 1s;
  animation-iteration-count: 1;
}
.active-color {
  color: $active-color;
}
.dark-active-color {
  color: $dark-active-color;
}
.black-color {
  color: $black-color-pure;
}
.grey-color {
  color: $grey-color;
}
.bg-active-color {
  background: $active-color;
}
.bg-white-shadow-r {
  background: $white-color;
  box-shadow: /*0px 4px 80px 20px rgba(0, 0, 0, 0.08)*/ 0px 4px 20px 11px
    rgb(0 0 0 / 8%);
}
.ReactModalPortal {
  .ReactModal__Overlay {
    z-index: 99999 !important;
  }
}
.modal-close{
  padding: 0;
  line-height: 1;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  border: none;
  background: $active-color;
  color: $white-color;
  transition: 0.3s all;
  &:hover{
    box-shadow: 0px 2px 7px rgba(127,137,161,.85);
    background: $dark-active-color;
  }
}
.line-height{
  line-height: 1;
}
.line-2-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  position: relative;
  margin: 0;
}
.line-4-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  position: relative;
  margin: 0;
}
.line-1-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  height: auto;
  position: relative;
  margin: 0;
}

#preloader,
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: $white-color;
  &:before {
    content: "";
    position: fixed;
    top: calc(50% - 30px);
    left: calc(50% - 30px);
    border: 6px solid $active-color;
    border-top-color: $white-color;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    -webkit-animation: animate-preloader 1s linear infinite;
    animation: animate-preloader 1s linear infinite;
  }
}
.loading_page {
  height: 100vh;
  text-align: center;
  color: $color-18;
  padding-top: 50px;
}
.back-to-top {
  position: fixed;
  display: none;
  right: 15px;
  bottom: 105px;
  z-index: 99999;
  @media (max-width: 575px) {
    bottom: 15px;
  }
  i {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    width: 40px;
    height: 40px;
    border-radius: 4px;
    background: $active-color;
    color: $white-color;
    transition: all 0.4s;
    &:hover {
      background: $footer-copy-right-color;
      color: $white-color;
    }
  }
}
.mobile-nav-toggle,
.mobile-search-toggle {
  i {
    color: $white-color;
  }
}
.mobile-logo {
  width: 50px;
}
.social-links {
  .dropdown-toggle {
    &::after {
      border-top: 0.3em solid $active-color;
    }
  }
}
#topbar {
  background: $top-bar-color-light;
  /* height: 45px; */
  font-size: 15px;
  transition: all 0.5s;
  font-family: $font-family-mulish/* $font-family-roboto */;
  font-weight: 200;
  .container {
    /* width: $header-width; */
  }
  .contact-info {
    a,.sub-menu-nav {
      line-height: 2;
      color: $top-bar-color-text;
      transition: 0.3s;
      padding: 0;
      font-weight: 600/* 600 */;
      text-transform: $text-transform-site;
      @include font-size-16();
      margin: $header-spacing;
      cursor: pointer;
      &:hover {
        /* text-decoration: underline; */
      }
    }
    i {
      margin-left: 15px;
      &:first-child {
        margin-left: 0;
      }
    }
  }
  .social-links {
    a {
      color: $normal-color;
      padding-left: 15px;
      display: inline-block;
      line-height: 1px;
      transition: 0.3s;
      font-weight: 500;
      &:hover {
        color: $black-color-pure;
      }
    }
    .currency-exchange-box {
      border: 2px solid $active-color;
      border-radius: 30px;
      padding: 0px 15px 0px 10px;
      margin-left: 15px;
      color: $black-color;
      font-weight: 500;
      background-color: transparent;
      background: url(../../../public/icons/caret-down.svg) no-repeat center
        right;
      outline: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      -ms-appearance: none;
      -o-appearance: none;
      background-position: 90%;
    }
    .topbar-search-box {
      background: $active-color;
      padding: 2px;
      height: 25px;
      width: 25px;
      line-height: normal;
      box-shadow: 0px 2px 3px rgb(0 0 0 / 20%);
      border-radius: 3px;
      color: $white-color;
      text-align: center;
      margin-left: 15px;
    }
    .accountMenuDropDown {
      a.dropdown-item {
        font-size: 14px;
        line-height: normal;
      }
    }
  }
}
.Toastify {
  *{
    font-family: $font-family-mulish;
    @include font-size-14();
    font-weight: 500;
  }
  .Toastify__toast{
    /* margin-bottom: 0; */
  }
  .Toastify__toast-container{
    padding: 0;
  }
  .Toastify__toast-container--bottom-center{
    bottom: 7em;
  }
}
[class^="icofont-"] {
  color: $active-color;
}
.bx-add-to-queue {
  color: $dark-active-color;
  font-size: 24px;
}
.already-to-queue{
  color: $color-16;
}
#topbar.topbar-scrolled {
  //   top: -40px;
}
.topbar-search-box {
  i {
    color: $white-color;
  }
}
.search-box{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  transition: 0.2s all;
  backdrop-filter: blur(12px);
  &.active{
    visibility: visible;
    opacity: 1;
  }
}
.social-links-image {
  width: 20px;
}
.sgadi-logo {
  position: absolute;
  background: $active-color;
  /* width: 20%; */
  max-width: 17%;
  height: auto/* calc(100% + 12px) */;
  /* justify-content: center;
  flex-direction: column; */
  padding: 0 5px 0 0;
  border-radius: 0 0px 30px 0;
  /* box-shadow: $sgadi-logo-shadow; */
  z-index: 1;
  padding-top: 33px;
  a {
    z-index: 1;
  }
  &::before {
    position: absolute;
    content: "";
    top: 0;
    height: 100%;
    background: $active-color;
    right: -8px /* 100% */;
    width: 1000%;
    z-index: 0;
    box-shadow: $sgadi-logo-shadow;
    transform: skew(-12deg);
    border-radius: 0 0px 20px 0;
  }
}
.donate-search-box {
  background: $white-color;
  border-radius: 5px;
  padding: 5px 8px;
  cursor: pointer;
  font-weight: 500;
  color: $dark-active-color;
  border: 1px solid $white-color;
  &:hover{
    border: 1px solid $dark-active-color;
    background-color: #eee;
  }
  i {
    color: $color-14;
  }
  svg{
    fill: $dark-active-color;
  }
  .icofont-search {
    &::before {
      font-weight: 800;
    }
  }
}
#header {
  background: $active-color;
  transition: all 0.5s;
  z-index: 1031;
  padding: 0px;
  /* top: 45px; */
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  a.topbar-search-box,.topbar-search-box {
    background: $white-color;
    border-radius: 5px;
    padding: 2px 8px;
    line-height: inherit;
    margin: 0;
    font-size: unset !important;
    /* height: 26.5px;
    width: 31px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center; */
    cursor: pointer;
    i {
      color: $color-14;
    }
    svg {
      fill: $color-14;
    }
    &::before{
      display: none;
    }
    .icofont-search {
      &::before {
        font-weight: 800;
      }
    }
  }
  .container {
    /* width: $header-width; */
  }
  .logo {
    font-size: 32px;
    /* margin: -50px 0 0 0; */
    margin: 0px 0 -47px 0;
    padding: 0;
    line-height: 1;
    font-weight: 600;
    letter-spacing: 0.8px;
    font-family: $font-family-poppins;
    a {
      color: #222222;
      text-align: center;
      &:before {
        display: none;
      }
      span {
        color: $active-color;
      }
    }
    img {
      /* padding: 20px 40px 0px; */
      padding: 16px;
      background: $active-color;
      width: 100%;
      border-radius: 30px 30px 0 0;
    }
  }
}
#header.header-scrolled {
  //   top: 0;
  //   padding: 15px;
}
.header-pr {
  padding-right: 80px !important;
}
.header-pl {
  padding-left: 80px !important;
}
.nav-menu {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  > ul {
    display: flex;
    > li {
      position: relative;
      white-space: nowrap;
      padding: $header-spacing /* 14px 0 14px 0px */;
      > a,.sub-menu-nav {
        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: -1px;
          left: 0;
          background-color: $white-color;
          visibility: hidden;
          width: 0px;
          transition: all 0.3s ease-in-out 0s;
          box-shadow: $header-shadow;
        }
      }
      .inspirator-text {
        @include font-size-10();
        color: $white-color;
        font-weight: 500;
        letter-spacing: 0.5px;
        &::before {
          display: none;
        }
      }
    }
  }
  a,.sub-menu-nav {
    display: block;
    position: relative;
    color: $white-color;
    transition: 0.3s;
    font-size: 17px;
    font-weight: 600;
    padding: 0;
    font-family: $font-family-mulish;
    text-transform: $text-transform-site;
    letter-spacing: normal;
    line-height: 2;
    cursor: pointer;
    &:hover {
      &:before {
        visibility: visible;
        width: 100%;
      }
      color: $active-color;
    }
  }
  li {
    &:hover {
      > a,.sub-menu-nav {
        &:before {
          visibility: visible;
          width: 100%;
        }
        color: $white-color;
      }
    }
  }
  .active {
    > a {
      &:before {
        visibility: visible;
        width: 100%;
      }
      color: $white-color;
      text-shadow: $header-shadow;
    }
  }
  .drop-down {
    ul {
      display: block;
      position: absolute;
      left: 26px;
      top: calc(100% + 30px);
      z-index: 99;
      opacity: 0;
      visibility: hidden;
      padding: 10px 0;
      background: $white-color;
      box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
      transition: 0.3s;
      a {
        padding: 10px 20px;
        font-size: 14px;
        font-weight: 500;
        text-transform: none;
        color: $normal-color;
        &:hover {
          color: $active-color;
        }
      }
      .active {
        > a {
          color: $active-color;
        }
      }
      li {
        &:hover {
          > a {
            color: $active-color;
          }
        }
      }
    }
    &:hover {
      > ul {
        opacity: 1;
        top: 100%;
        visibility: visible;
      }
    }
    li {
      min-width: 180px;
      position: relative;
    }
    > a {
      &:after {
        content: "\ea99";
        font-family: IcoFont;
        padding-left: 5px;
      }
    }
    .drop-down {
      ul {
        top: 0;
        left: calc(100% - 30px);
      }
      &:hover {
        > ul {
          opacity: 2;
          top: 0;
          left: 100%;
        }
      }
      > a {
        padding-right: 35px;
        &:after {
          content: "\eaa0";
          font-family: IcoFont;
          position: absolute;
          right: 15px;
        }
      }
    }
  }
}
.mobile-nav-toggle {
  position: fixed;
  left: 10px;
  top: 20px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
  padding: 0;
}
.mobile-search-toggle {
  position: fixed;
  right: 10px;
  top: 20px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 24px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  text-align: right;
}
.mobile-donate-toggle{
  border: 0;
  background: none;
  z-index: 9998;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
}
.width-header-desktop{
  width: 70%;
}
.btn{
  font-family: $font-family-quicksand;
  font-weight: 600;
}
.btn-expand,
.btn-expand-d {
  position: absolute;
  top: 5px;
  right: 15px;
  transition: 0.2s all;
  height: 32px;
  width: 32px;
  /* background: #e5e2e2;
  border: 1px solid #e5e2e2; */
  z-index: 2;
  &::after {
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: 0.2s all;
  }
}
.btn-expand-d {
  &::after {
    transform: translate(-50%, -50%) rotate(180deg);
  }
}
.mobile-nav {
  // position: fixed;
  // top: 64px;
  // right: 0;
  // bottom: 0;
  // left: 0;
  // z-index: 9999;
  // overflow-y: auto;
  // background: $white-color;
  // transition: ease-in-out 0.2s;
  // opacity: 0;
  // visibility: hidden;
  // border-radius: /*10px*/ 0;
  // padding: 10px 0;
  position: fixed;
  top: 66px;
  width: 100%;
  bottom: 0;
  left: -100%;
  z-index: 9999;
  overflow-y: auto;
  background: $white-color;
  transition: ease-in-out 0.2s all;
  /* opacity: 0; */
  visibility: visible;
  border-radius: /*10px*/ 0;
  padding: 10px 0;
  .sub-menu-nav{
    width: auto;
    top: unset;
    right: unset;
    height: auto;
    &.btn-expand{
      &::after{
        left: auto;
        right: 25px;
        transform: translateY(-50%);
      }
    }
    &.btn-expand-d{
      &::after{
        left: auto;
        right: 25px;
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }
  .sub-menu-nav + .btn-expand{
    display: none;
  }
  * {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  a,.sub-menu-nav {
    display: block;
    position: relative;
    color: #222222;
    padding: 10px 20px;
    font-weight: 200;
    outline: none;
    text-transform: $text-transform-site;
    /* border-bottom: 1px solid #e4e4e4; */
    cursor: pointer;
    &:hover {
      color: $active-color;
      text-decoration: none;
    }
  }
  .active {
    > a {
      color: $active-color;
      text-decoration: none;
    }
  }
  li {
    border-bottom: 1px solid #e4e4e4;
    &:hover {
      > a {
        color: $active-color;
        text-decoration: none;
      }
    }
  }
  .drop-down {
    > a {
      &:after {
        content: "\ea99";
        font-family: IcoFont;
        padding-left: 10px;
        position: absolute;
        right: 15px;
      }
      padding-right: 35px;
    }
    ul {
      display: none;
      overflow: hidden;
    }
    li {
      padding-left: 20px;
    }
  }
  .active.drop-down {
    > a {
      &:after {
        content: "\eaa1";
      }
    }
  }
}
.mobile-nav-overly,
.mobile-search-overly {
  width: 100%;
  height: 100%;
  z-index: 9997;
  top: 0;
  left: 0;
  position: fixed;
  background: /*rgba(9, 9, 9, 0.6)*/ transparent;
  overflow: hidden;
  display: none;
  transition: ease-in-out 0.2s;
}
.mobile-nav-active {
  overflow: hidden;
  .mobile-nav {
    opacity: 1;
    visibility: visible;
    left: 0;
  }
  .mobile-nav-toggle {
    i {
      color: $white-color;
    }
  }
}

@mixin mega-menu-link {
  font-family: $font-family-mulish/* $font-family-roboto */;
  font-style: normal;
  font-weight: 600;
  @include /* font-size-16() */ font-size-14();
  margin: 0 !important;
  color: $normal-color;
  line-height: 1.8;
  border-bottom: 1px dotted #d8d8d8;
  padding: 2px 0;
  &:hover {
    color: $active-color;
    text-decoration: none;
  }
  &:before {
    display: none;
  }
  &:first-child {
    padding-top: 0px;
  }
  &:last-child {
    border-bottom: none;
    padding-bottom: 0px;
  }
}

#topbar {
  .contact-info {
    .mega-menu-link {
      @include mega-menu-link();
    }

    a,.sub-menu-nav {
      &:before {
        content: "";
        position: absolute;
        width: 100%;
        height: 2px;
        bottom: 5px;
        left: 0;
        background-color: $active-color;
        visibility: hidden;
        width: 0px;
        transition: all 0.3s ease-in-out 0s;
      }
      &.active::before{
        visibility: visible;
        width: 100%;
      }
      &:hover {
        &:before {
          visibility: visible;
          width: 100%;
        }
      }
    }
  }
}

ul.mega-menu {
  background: #fef9f4;
  border-radius: 10px;
  padding: 15px;
  right: 0;
  left: 0;
  min-height: 245px;
  margin-top: 7px;
  border: none;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
  top: 82%;
  a {
    color: #000;
  }
  .mega-menu-link {
    @include mega-menu-link();
  }
  .mega-menu-inner {
    padding: 15px;
  }
  .mega-menu-inner-2 {
    border-radius: 10px;
    overflow: hidden;
    .mega-menu-inner {
      padding: 15px 20px;
    }
  }
}
#hero {
  width: 100%;
  height: 70vh;
  background-size: cover;
  position: relative;
  overflow: initial;
  &:before {
    content: "";
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }
  .container {
    position: relative;
    padding-top: 132px;
  }
  h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: #222222;
    font-family: $font-family-poppins;
    span {
      color: $active-color;
    }
  }
  .btn-get-started {
    font-family: $font-family-quicksand/* $font-family-roboto */;
    text-transform: $text-transform-site;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 1px;
    display: inline-block;
    padding: 10px 28px;
    border-radius: 4px;
    transition: 0.5s;
    color: $white-color;
    background: $active-color;
    &:hover {
      background: #247cf0;
    }
  }
  .btn-watch-video {
    font-size: 16px;
    display: inline-block;
    padding: 10px 25px 8px 40px;
    transition: 0.5s;
    margin-left: 25px;
    color: #222222;
    position: relative;
    font-weight: 600;
    i {
      color: $active-color;
      font-size: 32px;
      position: absolute;
      left: 0;
      top: 7px;
      transition: 0.3s;
    }
    &:hover {
      color: $active-color;
      i {
        color: #3b8af2;
      }
    }
  }
}
section {
  padding: 50px 0;
  overflow: hidden;
}
.section-bg {
  background-color: #f6f9fe;
}
.section-title {
  text-align: center;
  padding-bottom: 30px;
  h2 {
    font-size: 13px;
    letter-spacing: 1px;
    font-weight: 700;
    padding: 8px 20px;
    margin: 0;
    background: #e7f1fd;
    color: $active-color;
    display: inline-block;
    text-transform: $text-transform-site;
    border-radius: 50px;
  }
  h3 {
    margin: 15px 0 0 0;
    font-size: 32px;
    font-weight: 700;
    span {
      color: $active-color;
    }
  }
  p {
    margin: 15px auto 0 auto;
    font-weight: 600;
  }
}
.breadcrumbs {
  padding: 20px 0;
  background-color: #f1f6fe;
  min-height: 40px;
  margin-top: 134px;
  h2 {
    font-size: 24px;
    font-weight: 300;
    margin: 0;
  }
  ol {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 14px;
    li + li {
      padding-left: 10px;
      &::before {
        display: inline-block;
        padding-right: 10px;
        color: #6c757d;
        content: "/";
      }
    }
  }
}
.breadcrumb-site {
  .breadcrumb-item + .breadcrumb-item::before {
    color: $color-19;
    content: "::";
    font-weight: 500;
    font-family: $font-family-mulish-text/* $font-family-roboto */;
    font-style: normal;
  }
  .breadcrumb-item {
    a {
      font-family: $font-family-mulish-text/* $font-family-roboto */;
      font-style: normal;
      font-weight: 500;
      @include /* font-size-18-v1() */ font-size-16();
      text-align: justify;
      color: $color-19;
      text-transform: capitalize;
    }
    &:hover {
      a {
        color: $black-color-pure;
      }
    }
    &:last-child {
      a {
        color: $color-1;
      }
    }
  }
}
.content {
  h3 {
    font-weight: 600;
    font-size: 26px;
  }
  h4 {
    font-weight: 600;
    font-size: 20px;
  }
  ul {
    list-style: none;
    padding: 0;
    li {
      /* display: flex; */
      align-items: flex-start;
      margin-bottom: 35px;
      /* &:first-child {
        margin-top: 35px;
      } */
    }
    i {
      background: $white-color;
      box-shadow: 0px 6px 15px rgba(16, 110, 234, 0.12);
      font-size: 24px;
      padding: 20px;
      margin-right: 15px;
      color: $active-color;
      border-radius: 50px;
    }
    h5 {
      font-size: 18px;
      color: #555555;
    }
    p {
      font-size: 15px;
    }
  }
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.owl-nav-en {
  .owl-nav.disabled {
    display: none;
    transition: 0.3s all;
  }
  &:hover {
    .owl-nav.disabled {
      display: block;
      margin: 0;
    }
  }
  .owl-nav {
    margin: 0;
  }
}
.owl-prev,
.owl-next,
.slick-slider .slick-next,
.slick-slider .slick-prev {
  position: absolute;
  top: 50%;
  left: 1%;
  transform: translate(-50%, -50%);
  background: $white-color !important;
  height: 40px;
  width: 40px;
  border-radius: 50% !important;
  box-shadow: 0px 4px 14px 1px rgb(0 0 0 / 16%);
  margin: 0 !important;
  z-index: 1;
}
.owl-next,
.slick-slider .slick-next {
  left: 99%;
}
.slick-slider .slick-next,
.slick-slider .slick-prev {
  &::before {
    font-family: IcoFont !important;
    color: $color-18;
  }
}
.slick-slider .slick-next {
  &::before {
    content: "\eab8";
  }
}
.slick-slider .slick-prev {
  &::before {
    content: "\eab5";
  }
}
.event-page-links{
  .event-page-link{
    color: $dark-active-color;
    transition: 0.2s all;
    &:hover{
      color: $black-color;
      svg{
        path{
          stroke: $black-color;
        }
      }
    }
  }
  svg{
    path{
      stroke: $dark-active-color;
    }
  }
}
#footer {
  padding: /* 60px 0 0 0 */ 0px;
  font-size: 14px;
  background: $color-6;
  font-family: $font-family-lato !important;
  letter-spacing: 0.5px;
  position: relative;
  .Bottom_Flower {
    position: absolute;
    bottom: 60%;
    width: 370px;
    left: 25px;
    z-index: 0;
  }
  .bottom_curve {
    position: absolute;
    z-index: 1;
    left: 39%;
    width: auto;
    top: -15px;
    img {
      width: 100%;
    }
  }
  .footer_bg {
    position: absolute;
    background: url(../../../public/img/footer_bg.png);
    top: 60px;
    left: 0;
    height: 81%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 1;
  }
  .footer-bottom {
    background: $color-12;
    padding: 15px 0;
    color: $white-color;
    font-size: 15px;
    font-family: $font-family-mulish-text/* $font-family-roboto */;
    z-index: 2;
    position: relative;
    a {
      color: $white-color;
    }
  }
  .footer-newsletter {
    padding: 70px 0 450px 0;
    text-align: center;
    font-size: 15px;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
    overflow: hidden;
    h4 {
      font-size: 24px;
      margin: 0 0 20px 0;
      padding: 0;
      line-height: 1;
      font-weight: 600;
    }
    form {
      margin-top: 20px;
      padding: 0;
      position: relative;
      box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
      text-align: left;
      input[type="email"] {
        border: 0;
        padding: 12px 20px;
        width: calc(100% - 120px);
        font-family: $font-family-poppins;
        background: $white-color;
        border: 1px solid $active-color;
        box-sizing: border-box;
        border-radius: 5px;
        overflow: hidden;
      }
      input[type="submit"] {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        border: 0;
        background: none;
        font-size: 16px;
        padding: 0 20px;
        background: $footer-color;
        color: $white-color;
        transition: 0.3s;
        border-radius: 0 4px 4px 0;
        box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
        text-transform: $text-transform-site;
        font-family: $font-family-poppins;
        font-weight: 500;
        &:hover {
          background: $footer-color-hover;
          transition: 0.2s all;
        }
      }
    }
  }
  .footer-top {
    padding: /* 70px 0 0px 0 */ 30px 0 0px 0;
    color: $white-color;
    font-family: $font-family-lato;
    letter-spacing: 0.5px;
    position: relative;
    background: $orange-linear;
    background-size: 100%;
    background-repeat: no-repeat;
    z-index: 1;
    .sub-title-alt {
      color: $white-color;
      &::after {
        background: $white-color;
        width: 100px;
      }
    }
    .description {
      color: $white-color;
      font-family: $font-family-mulish-text/* $font-family-roboto */;
      font-weight: 300;
      font-size: 16px;
      line-height: 1.3;
    }
    .footer-contact-us {
      .description {
        padding-left: 30px;
        position: relative;
        margin-bottom: 10px;
        text-align: left;
        a {
          color: $white-color;
        }
        .footer-contact-icon {
          position: absolute;
          top: 0;
          left: 3px;
          width: 15px;
        }
      }
    }
    .footer-contact {
      margin-bottom: 30px;
      h3 {
        font-size: 24px;
        margin: 0 0 15px 0;
        padding: 2px 0 2px 0;
        line-height: 1;
        font-weight: 700;
      }
      p {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 0;
      }
    }
    h4 {
      font-size: 16px;
      font-weight: normal;
      position: relative;
      margin-bottom: 10px;
      color: $white-color;
      text-transform: none;
      font-family: $font-family-roboto;
    }
    .footer-links {
      margin-bottom: 15px;
      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        i {
          padding-right: 2px;
          font-size: 18px;
          line-height: 1;
        }
        li {
          padding: 0;
          display: flex;
          align-items: center;
          &:first-child {
            padding-top: 0;
          }
        }
        a {
          transition: 0.3s;
          display: inline-block;
          color: $white-color;
          &:hover {
            text-decoration: none;
            color: $footer-copy-right-color;
          }
        }
      }
    }
    .social-links {
      a {
        font-size: 18px;
        display: inline-block;
        color: $white-color;
        line-height: 1;
        padding: 8px 0;
        margin-right: 4px;
        border-radius: 4px;
        text-align: center;
        width: 36px;
        height: 36px;
        transition: 0.3s;
        &:hover {
          background: $active-color;
          text-decoration: none;
        }
      }
    }
  }
  .copyright {
    text-align: center;
    float: none;
    color: $footer-copy-right-color;
    img {
      padding: 0 15px;
    }
    p {
      margin: 10px 0;
    }
  }
  .credits {
    float: right;
    text-align: center;
    font-size: 13px;
  }
  .follow-us {
    gap: 5px;
    .footer-social {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      i {
        font-size: 16px;
      }
    }
  }
}
.footer-newsletter-form {
  position: relative;
  margin-bottom: 15px;
  input[type="email"] {
    border: 0;
    padding: 15px 20px;
    width: calc(100% - 108px);
    background: transparent;
    border: 2px solid $white-color;
    box-sizing: border-box;
    border-radius: 10px 0px 0 10px;
    overflow: hidden;
    font-family: $font-family-roboto;
    color: $white-color;
    font-size: 16px;
    &::-webkit-input-placeholder {
      /* Edge */
      color: $white-color;
    }

    &:-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: $white-color;
    }

    &::placeholder {
      color: $white-color;
    }
  }
  input[type="submit"] {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    border: 0;
    background: none;
    font-size: 15px;
    padding: 0 15px;
    background: $white-color;
    color: $orange-color;
    transition: 0.3s;
    border-radius: 0 10px 10px 0;
    box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
    text-transform: $text-transform-site;
    font-weight: 600;
    font-family: $font-family-roboto;
  }
}
.col-xs-2 {
  background: #00f;
  color: $white-color;
}
.col-half-offset {
  margin-left: 4.166666667%;
}
.only-title-banner {
  font-family: $font-family-quicksand-title/* $font-family-roboto */;
  font-style: normal;
  font-weight: 500;
  @include /* font-size-37(); */ font-size-30() /* font-size-24-v1() */;
  color: $active-color;
  padding: 5px 15px;
}
.only-social-with-date {
  gap: 0 15px;
  position: relative;
  z-index: 1;
  .social-date {
    font-family: $font-family-mulish-text/* $font-family-roboto */;
    font-weight: 700;
    @include font-size-13();
    color: #b6aaaa;
    padding: 7px 12px;
  }
}
.st-inline-share-buttons{
  display: flex !important;
  flex-direction: row;
  gap: 4px;
  opacity: 1 !important;
  @media (max-width: 500px) {
    flex-direction: column;
  }
  .st-btn{
    margin: 0 !important;
    display: flex !important;
    min-width: auto !important;
    .st-label{
      display: block !important;
    }
  }
}
#main {
  background: transparent;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}
.banner {
  width: 100%;
  height: 40vh;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  padding: 0;
  .banner-items {
    height: 100%;
    background-size: cover;
    position: relative;
    background-position: center;
    background-repeat: no-repeat;
  }
}
.banner_slider {
  width: 100%;
  height: 75vh;
  background-size: cover;
  background-attachment: fixed;
  position: relative;
  padding: 0;

  .bannerDots {
    /* position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    button {
      border: none;
      background: none;
    }
    .owl-dot.active span,
    .owl-theme .owl-dots .owl-dot:hover span {
      background: $dark-active-color;
    }
    .owl-dot span {
      background: $active-color;
      height: 10px;
      width: 10px;
      display: block;
      border-radius: 50%;
    }
  }
  .carousel {
    height: 100%;
    display: block;
    .slick-dots {
      /* bottom: -10px;
      position: relative; */
      bottom: 7px;
      position: absolute;
      li {
        margin: 0 2px;
        button:before {
          font-size: 14px;
          content: "";
          background: $black-color-05-opacity;
          border-radius: 50%;
          border: 1px solid #ffffffa7;
          width: 13px;
          height: 13px;
          opacity: unset;
        }
        &.slick-active{
          button:before {
            background: $dark-active-color;
          }
        }
      }
      li.slick-active button:before {
        /* opacity: 1;
        color: $color-18; */
      }
    }
    .slick-list {
      height: 100%;
      .slick-track {
        height: 100%;
        .slick-slide {
        }
        .slick-slide > div {
          height: 100%;
          .banner-items {
            img {
              height: 100%;
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
  .owl-stage-outer {
    height: 100%;
  }
  .owl-stage {
    height: 100%;
  }
  .owl-item {
    height: 100%;
    height: 100%;
  }
  .item {
    height: 100%;
  }
  .img-responsive {
    height: 100%;
  }
  .container {
    height: 100%;
    z-index: 1;
  }
  .banner-items {
    height: 100%;
    background-size: cover;
    position: relative;
    background-position: center;
    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      //   background: linear-gradient(
      //     321.37deg,
      //     rgba(11, 13, 38, 0) 29.54%,
      //     #0b1d26 96.69%
      //   );
      z-index: 0;
    }
    &:before {
      content: "";
      position: absolute;
      bottom: 0;
      right: 0;
      height: 300px;
      width: 500px;
      //   background: linear-gradient(
      //     328.21deg,
      //     #0b1d26 11.3%,
      //     rgba(11, 29, 38, 0.44) 36.45%,
      //     rgba(196, 196, 196, 0) 50.71%
      //   );
      z-index: 0;
    }
  }
  .banner-text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    max-width: 500px;
  }
}
.banner_slider.owl-carousel.owl-theme.owl-loaded.owl-drag {
  height: 100%;
}
#hero.banner_slider.fixed-banner {
  height: 75vh;
}
.banner_slider.fixed-banner {
  .banner-items {
    width: 100%;
  }
}
.curve-effect-bottom {
  position: absolute;
  bottom: -3px;
  width: 100%;
  z-index: 1;
  img {
    width: 100%;
  }
}
.curve-effect-top {
  position: absolute;
  bottom: 99%;
  width: 100%;
  z-index: 1;
  img {
    width: 100%;
  }
}
.owl-carousel {
  .owl-slide-animated {
    transform: translateX(20px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.05s;
  }
  .owl-slide-animated.is-transitioned {
    transform: none;
    opacity: 1;
    visibility: visible;
    transition: all 0.5s;
  }
  .banner-sub-title.is-transitioned {
    transition-delay: 0.2s;
  }
  .banner-title.is-transitioned {
    transition-delay: 0.35s;
  }
  .banner-price.is-transitioned {
    transition-delay: 0.5s;
  }
  .banner-btn-block.is-transitioned {
    transition-delay: 0.5s;
  }
}
.banner-carousel.owl-theme {
  .owl-nav {
    position: absolute;
    z-index: 9;
    bottom: 100px;
    right: 210px;
  }
}
.banner-carousel.owl-carousel {
  .owl-nav {
    button.owl-next {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.7);
      &:hover {
        background: $active-color;
        transition: 0.2s all;
      }
    }
    button.owl-prev {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background: rgba(255, 255, 255, 0.7);
      &:hover {
        background: $active-color;
        transition: 0.2s all;
      }
    }
    button {
      [class^="icofont-"] {
        color: $white-color;
      }
    }
  }
}
.navigation-counter {
  position: absolute;
  bottom: 90px;
  right: 0;
  z-index: 9;
  color: $white-color;
  width: 200px;
  font-family: $font-family-lato;
  .slider-counter {
    border-bottom: 2px solid $white-color;
    padding: 0px 0 4px 25px;
  }
  .slider-counter-current {
    font-weight: 800;
    font-size: 22px;
    color: $white-color;
    line-height: normal;
  }
}
.slider-counter {
  span {
    color: rgba(255, 255, 255, 0.6);
  }
}
.navigation-counter-text {
  color: $white-color;
  font-weight: 600;
  padding: 4px 0 0 4px;
}
.banner-text-2{
  position: absolute;
  z-index: 9;
  display: inline-flex;
  gap: 10px;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
  opacity: 0.8;
  @media (max-width: 768px) {
    min-width: calc(100% - 20px);
  }
  @media (max-width: 575px) {
    min-width: calc(100% - 15px);
  }
}
.banner-sub-title {
  font-family: $font-family-quicksand-title/* $font-family-roboto */;
  font-style: normal;
  font-weight: 600;
  @include font-size-14()/* font-size-20-v1() */;
  color: $black-color-pure;
  margin: 0;
  /* position: absolute; */
  /* bottom: 15px;
  left: 50%;
  transform: translate(-50%, 0%); */
  background-color: $top-bar-color-light;
  padding: 7px 15px;
  border-radius: 10px;
  z-index: 9;
  /* transform: translateX(20px); */
  opacity: 0;
  visibility: hidden;
  transition: all 1.2s;
}
.slick-active .banner-sub-title {
  /* transform: translateX(-50%); */
  opacity: 1;
  visibility: visible;
}
.banner-title {
  font-family: $font-family-quicksand-title/* $font-family-roboto */;
  font-style: normal;
  font-weight: 800;
  @include banner-title-font-size();
  color: $black-color-pure;
  margin: 0;
  /* position: absolute; */
  /* bottom: 70px;
  left: 50%;
  transform: translate(-50%, 0%); */
  background-color: $top-bar-color-light;
  padding: 10px 25px;
  border-radius: 10px;
  z-index: 9;
  /* transform: translateX(20px); */
  opacity: 0;
  visibility: hidden;
  transition: all 1.2s;
}
.slick-active .banner-title {
  /* transform: translateX(-50%); */
  opacity: 1;
  visibility: visible;
}
.banner-price {
  font-family: $font-family-poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 30px;
  color: $white-color;
  margin: 0;
}
.banner-btn {
  padding: 10px;
  border: 1px solid $white-color;
  border-radius: 3px;
  line-height: 1;
  font-size: 14px;
  margin-top: 15px;
  color: $white-color;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  transition: 0.2s all;
  &:hover {
    color: $white-color;
    background: $active-color;
    border: 1px solid $active-color;
  }
}
.title {
  font-style: normal;
  line-height: normal;
  color: $black-color-pure;
  text-transform: $text-transform-site;
  font-family: $font-family-quicksand-title/* $font-family-roboto-slab */;
  @include font-size-36();
  font-weight: 700;
  position: relative;
  letter-spacing: -1.2px;
}
.title.type-2 {
  font-family: $font-family-quicksand-title/* $font-family-roboto */;
  font-weight: 700;
  @include font-size-26();
  text-transform: $text-transform-site;
  color: $color-18;
  letter-spacing: 0.1px;
}
.title.type-3 {
  font-family: $font-family-quicksand-title/* $font-family-roboto */;
  font-weight: 700;
  @include font-size-18-v3();
  text-transform: $text-transform-site;
  color: $color-18;
  letter-spacing: 0.1px;
}

.title-2 {
  font-family: $font-family-quicksand-title/* $font-family-roboto */;
  font-style: normal;
  font-weight: 600;
  text-transform: $text-transform-site;
  color: $color-18;
  @include /* font-size-36() */ font-size-30();
}
.title-3 {
  font-family: $font-family-quicksand-title/* $font-family-roboto */;
  font-style: normal;
  font-weight: 600;
  text-transform: $text-transform-site;
  color: $color-18;
  @include font-size-26();
}

.sub-title-alt {
  font-family: $font-family-quicksand-title/* $font-family-futura */;
  text-transform: $text-transform-site;
  @include font-size-20-v2();
  color: $black-color-pure;
  font-weight: 600;
  position: relative;
  margin: 0 0 12px 0;
  padding: 0 0 15px 0;
  line-height: 1.2;
  &:after {
    position: absolute;
    content: "";
    left: 0;
    bottom: 0;
    width: 80%;
    height: 3px;
    background: $dark-active-color;
  }
}

.description {
  font-style: normal;
  font-weight: 500;
  @include font-size-17();
  line-height: normal;
  text-align: justify;
  color: $black-color-pure;
  margin-bottom: 20px;
}
.white-color {
  color: $white-color;
}
.bg-gradient {
  background-image: linear-gradient(
    to right,
    $active-color,
    #5c5e62,
    $active-color
  );
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  background-size: 200% 100%;
  &:hover {
    border-color: transparent;
    background-position: 100% 0;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
}
.btn-primary {
  background-image: linear-gradient(135deg, $btn-primary 0%, $color-3 100%);
  border-radius: 10px;
  color: $white-color;
  moz-transition: all 0.4s ease-in-out;
  -o-transition: all 0.4s ease-in-out;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  padding: 10px 30px;
  border: none;
  background-size: 200% 100%;
  box-shadow: $btn-box-shadow;
  letter-spacing: 0.5px;
  @include font-size-24;
  &:hover {
    border-color: transparent;
    background-position: 98% 0;
    moz-transition: all 0.4s ease-in-out;
    -o-transition: all 0.4s ease-in-out;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  &:active {
    border-color: transparent;
  }
  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(176 163 120 / 25%);
  }
}
.btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgb(176 163 120 / 25%);
}
.form-control {
  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(176 163 120 / 25%);
    border-color: $active-color;
  }
  border-color: $active-color;
  @include font-size-16();
  padding: 10px 15px/* 12px 25px */;
  background: $white-color;
  border: 1px solid $color-20;
  border-radius: 10px;
  font-family: $font-family-mulish-text/* $font-family-roboto */;
  font-weight: 500;
  &::-webkit-input-placeholder {
    /* Edge */
    color: $grey-color-05-opacity;
    font-weight: 400;
  }

  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $grey-color-05-opacity;
    font-weight: 400;
  }

  &::placeholder {
    color: $grey-color-05-opacity;
    font-weight: 400;
  }
}
select.form-control {
  /* font-weight: 400; */
  @include font-size-16();
  /* font-family: $font-family-futura-bt; */
  padding: 10px 35px 10px 15px/* 12px 40px 12px 20px */;
  background-image: url("../../../public/icons/selectArrow.svg");
  background-repeat: no-repeat;
  background-position: calc(100% - 10px) 50%;
  text-transform: $text-transform-site;
  background-size: 10px;
}
select.form-control.calendar-title-date {
  padding: unset;
  /* border: 1px solid transparent; */
  text-align: center;
  padding: 2px 10px;
  /* &:hover{
    border: 1px solid $color-20;
  }
  &:active {
    border-color: transparent;
  } */
  &:focus {
    box-shadow: unset;
    /* border-color: transparent; */
  }
  option{
    @include font-size-13();
    text-align: left;
  }
}
select.form-control.select-2{
  padding: 2px 20px 2px 10px;
  width: max-content;
  @include font-size-24()/* font-size-14() */;
  font-weight: 700;
}
.form-control.with-bg {
  background-color: $color-8;
}
.border-radius-top-left {
  border-top-left-radius: 0px;
}
.border-radius-top-right {
  border-top-right-radius: 0px;
}
.border-radius-bottom-left {
  border-bottom-left-radius: 0px;
}
.border-radius-bottom-right {
  border-bottom-right-radius: 0px;
}
.border-radius-15px {
  border-radius: 15px;
}
.border-radius-5px {
  border-radius: 5px;
}
.border-radius-0px {
  border-radius: 0px;
}
.image-text {
  font-family: $font-family-mulish-text/* $font-family-roboto */;
  font-style: normal;
  font-weight: 500;
  @include /* font-size-16() */ font-size-14();
  text-align: center;
  color: $black-color-pure;
}
.image-with-text-container {
  .image-with-text-single {
    position: relative;
    .image-with-text-image {
      position: relative;
      overflow: hidden;
      .youtube-icon {
        position: absolute;
        bottom: 15px;
        left: 15px;
        z-index: 1;
        display: flex;
        transition: 0.2s all;
        max-width: 40px;
      }
    }
    &:hover {
      transition: 0.2s all;
      .image-with-text-image {
        .youtube-icon {
          display: flex;
        }
        &::after {
          position: absolute;
          content: "";
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          background: rgba(84, 29, 0, 0.65);
          backdrop-filter: blur(4px);
          overflow: hidden;
        }
      }
      .image-text {
        color: $active-color;
      }
    }
  }
}
.image-with-text-msonary {
  &.Downloadable{
    /* .image-with-text-msonary-image { */
      .download-count{
        width: 30px;
        left: unset;
        right: 15px;
        bottom: 15px;
        .icon{
          width: 100%;
          height: 100%;
          img{
            width: 80%;
          }
        }
      }
      &:hover {
        .download-count {
          bottom: 15px;
        }
      }
    /* } */
  }

  .image-with-text-msonary-image {
    position: relative;
    overflow: hidden;
    .gallery-image-img{
      transform: scale(1);
      transition: 0.3s all;
    }
    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 1;
      display: none;
      transition: 0.3s all;
      width: 100px;
    }
  }
  &:hover {
    transition: 0.3s all;
    cursor: pointer;
    .image-with-text-msonary-image {
      .gallery-image-img{
        transform: scale(1.05);
        @media (max-width: 768px) {
          transform: scale(1);
        }
      }
      .icon {
        display: flex;
      }
      &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background: rgba(84, 29, 0, 0.65);
        /* backdrop-filter: blur(4px); */
        overflow: hidden;
        display: none;
      }
    }
    .image-text {
      color: $active-color;
    }
  }
  &.Downloadable{
    .image-with-text-msonary-image {
      &::after {
        display: none;
      }
    }
  }
}
.btn.focus {
  box-shadow: 0 0 0 0.2rem rgb(176 163 120 / 25%);
}
.btn {
  &:focus {
    box-shadow: 0 0 0 0.2rem rgb(176 163 120 / 25%);
  }
}
.grid--masonry {
  display: grid;
  grid-template-columns: repeat(3, auto);
  grid-gap: 15px;
  grid-auto-rows: 0px;
  .masonry-item {
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    position: relative;
    &:before {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      height: 20%;
      width: 100%;
      background: linear-gradient(360deg, #474747 0%, rgba(69, 69, 69, 0) 100%);
      z-index: 1;
    }
  }
}
.masonry-content {
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
  margin-bottom: 30px;
  position: relative;
}
.image_box {
  background: #b8b19e;
  overflow: hidden;
  position: relative;
  padding-bottom: calc(100% * 0.53);
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  -ms-transform-style: preserve-3d;
  -o-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  img {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    -o-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -o-transition: opacity 0.2s !important;
    -ms-transition: opacity 0.2s !important;
    -moz-transition: opacity 0.2s !important;
    -webkit-transition: opacity 0.2s !important;
    transition: opacity 0.2s !important;
    height: calc(100% + 2px);
    object-fit: cover;
  }
  img.lazyloaded {
    opacity: 1;
  }
}
.section-bottom {
  padding-bottom: 160px;
}
.bg-2 {
  background: $background-color-2;
}
.bg-grey-color{
  background: $grey-color;
}
.border-active-color {
  border: 1px solid $active-color-2;
}
.key-facts-inner {
  min-width: 100px;
  padding: 0 20px;
  margin-bottom: 15px;
}
.key-facts-img {
  width: 50px;
  height: 50px;
  max-height: 50px;
  max-width: 50px;
  margin-bottom: 10px;
}
.key-facts-title {
  color: $black-color;
  font-weight: 600;
  margin: 0 0 5px 0;
  font-size: 15px;
}
.key-facts-text {
  color: $black-color;
  font-size: 14px;
  margin: 0;
  font-weight: 600;
}
.key-facts-img-arrow {
  position: absolute;
  right: 12%;
}
.premium {
  hr {
    border-color: #eaeaea;
    margin: 10px 0 10px 0;
  }
}
.tour-title {
  font-weight: 600;
  margin: 0 0 5px 0;
  font-size: 18px;
  position: relative;
  padding-right: 60px;
}
.titles {
  position: relative;
}
.start-icon-text {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: 600;
}
.flash.tour-title {
  padding-left: 20px;
}
.flash-icon {
  position: absolute;
  top: 3px;
  width: 15px;
  left: 0px;
}
.start-icon {
  width: 20px;
}
.star-text {
  padding-left: 5px;
}
.tour-sub-title {
  margin: 0;
}
.normal {
  .price-and-buttons {
    .price {
      border: 1px solid rgba(31, 41, 51, 0.4);
    }
  }
}
.price-and-buttons-text {
  line-height: 2;
}
.days-text {
  line-height: 1;
  position: absolute;
  top: 50%;
  transform: translate(0px, -50%);
  width: 100%;
  font-weight: 600;
  font-size: 20px;
  span {
    font-size: 12px;
    display: block;
  }
}
.price-text-tour-details {
  color: #616e7c;
  font-size: 20px;
  font-weight: 600;
}
.price-tour-details {
  color: $black-color;
}
.price-text-section {
  margin-bottom: 35px;
}
.tour-menu {
  .nav-link {
    padding: 0;
  }
}
.tour-all-details {
  margin: 0 0 35px 0;
  .title {
    margin: 0 0 30px 0;
    text-transform: $text-transform-site;
  }
}
ul.ul-list {
  padding: 0 0 0 60px;
  margin: 0;
  li {
    font-family: $font-family-poppins;
    margin: 7px 0;
    font-size: 15px;
    color: $black-color;
    font-weight: 500;
    position: relative;
    list-style: none;
    &:before {
      position: absolute;
      content: "";
      top: 8px;
      left: -25px;
      height: 6px;
      width: 6px;
      background-color: $active-color-2;
      transform: rotate(45deg);
    }
  }
}
.accordion-option {
  position: absolute;
  top: 0;
  right: 15px;
  .toggle-accordion {
    &:before {
      content: "Expand All";
    }
  }
  .toggle-accordion.active {
    &:before {
      content: "Collapse All";
    }
  }
}
#enquireModal {
  .modal-header {
    border-color: transparent;
    padding-top: 35px;
  }
  .modal-footer {
    border-color: transparent;
    justify-content: center;
    padding: 0px 0px 30px;
  }
  .modal-content {
    padding: 0 80px;
    border-color: transparent;
  }
}
.enquiry-text {
  font-size: 13px;
  font-weight: 600;
}
textarea.form-control {
  resize: none;
}
.booking-details-section {
  padding: 50px 0 0 0;
}
.custom02 {
  input[type="radio"] {
    display: none;
    &:checked + label {
      &::before {
        background: #fff;
        border: 2px solid $active-color;
      }
      &::after {
        opacity: 1;
        -webkit-transform: scale(1);
        transform: scale(1);
      }
    }
  }
  input[type="checkbox"] {
    display: none;
    &:checked + label {
      &::before {
        background: #fff;
        border: 2px solid $active-color;
      }
      &::after {
        opacity: 1;
        -webkit-transform: scale(1) rotate(-45deg);
        transform: scale(1) rotate(-45deg);
      }
    }
  }
  label {
    position: relative;
    display: inline-block;
    padding: 3px 3px 3px 35px;
    cursor: pointer;
    @include font-size-16();
    font-weight: bold;
    &::before {
      position: absolute;
      content: "";
      top: 13px;
      border-radius: 100%;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      left: 0;
      width: 20px;
      height: 20px;
      margin-top: -10px;
      background: #f3f3f3;
      border: 2px solid #ccc;
    }
    &::after {
      position: absolute;
      content: "";
      top: 13px;
      border-radius: 100%;
      -webkit-transition: all 0.2s;
      transition: all 0.2s;
      opacity: 0;
      left: 4px;
      width: 12px;
      height: 12px;
      margin-top: -6px;
      background: $active-color;
      -webkit-transform: scale(2);
      transform: scale(2);
    }
    &:hover {
      &::before {
        background: #fff;
      }
    }
  }
  .checkbox {
    label {
      margin: 0 0 0 0;
      @include font-size-16();
      text-align: left;
      &::before {
        border-radius: 0;
      }
      &::after {
        border-radius: 0;
        content: "";
        width: 11px;
        height: 7px;
        position: absolute;
        top: 14px;
        left: 5px;
        border: 3px solid $active-color;
        border-top: none;
        border-right: none;
        background: transparent;
        transform: scale(2) rotate(-45deg);
      }
    }
  }
}
.custom02.radio-section {
  label {
    font-size: 15px;
    margin: 0;
  }
}
.checkbox-section {
  background: $white-color;
  border: 1px solid #dadada;
  box-shadow: 1px 2px 5px 0px #dadada;
  .checkbox {
    &:not(:last-child) {
      border-bottom: 1px solid #dadada;
    }
    padding: 15px 25px;
  }
}
.radio-section {
  background: $white-color;
  border: 1px solid #dadada;
  box-shadow: 1px 2px 5px 0px #dadada;
  .radio {
    padding: 15px 25px;
  }
  .description {
    font-family: $font-family-lato;
    font-size: 14px;
    color: $grey-color;
  }
}
.terms-condition-section {
  background: transparent;
  border: 1px solid transparent;
  box-shadow: none;
}
.price-text {
  font-weight: 600;
  font-size: 14px;
}
.grey-control {
  border-color: $grey-color-05-opacity;
  box-shadow: 1px 2px 5px 0px #dadada;
}
.traveller-form {
  padding: 15px 0;
}
hr.dashed {
  border-style: dashed;
  border-color: $grey-color-05-opacity;
  margin: 0px 0 20px 0;
}
.file-control {
  color: $grey-color;
  margin-top: 15px;
  margin-bottom: 0px;
  label {
    padding: 0 0 0 40px;
    position: relative;
  }
  .file-control-icon {
    height: 20px;
    width: 20px;
    position: absolute;
    top: 50%;
    left: 10px;
    transform: translate(0px, -50%);
  }
  [type="file"] {
    opacity: 0;
    position: absolute;
    z-index: -1;
  }
}
.file-control-attach {
  padding: 5px 15px;
  margin: 0 0 0 45px;
}
.bg-border-section {
  .with-bg {
    border-radius: 5px;
    height: 100%;
    text-align: left;
  }
  .with-border {
    border-radius: 5px;
    height: 100%;
    text-align: left;
  }
}
.bg-border-section.checkout-section {
  .with-bg {
    border-radius: 5px 5px 0 0;
  }
  .with-border {
    border-radius: 5px 5px 0 0;
  }
}
.checkout-section {
  .btn-primary {
    font-weight: 600;
    font-size: 20px;
    padding: 15px 30px;
    border-radius: 0px 0px 5px 5px;
  }
}
.checkout-tab-nav.nav-tabs {
  border-color: transparent;
  .nav-link {
    font-size: 13px;
    color: $normal-color;
    font-weight: 600;
    border-color: transparent;
    padding: 0px 20px 5px 0;
  }
  .nav-item.show {
    .nav-link {
      color: $black-color-pure;
    }
  }
  .nav-link.active {
    color: $black-color-pure;
  }
}
label.error {
  color: #ff0000;
  font-size: 14px;
}
@mixin main-title-color($color) {
  background: $color;
}
.main-title {
  @include font-size-36();
  font-family: $font-family-quicksand-title/* $font-family-roboto-slab */;
  text-transform: $text-transform-site;
  font-weight: 500;
  color: $black-color-pure;
  position: relative;
  padding: 0 0 30px 0;
  letter-spacing: -1.2px;
  .main-title-icon {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 0%);
    fill: $dark-active-color;
    stroke: $dark-active-color;
    * {
      fill: $dark-active-color;
      stroke: $dark-active-color;
    }
  }
  &::after {
    /* position: absolute;
    content: "";
    left: 50%;
    bottom: 0;
    transform: translate(-50%, 0%);
    background: url("../../../public/img/Seperator.svg");
    height: 25px;
    width: 165px;
    background-size: 100%;
    background-repeat: no-repeat; */
  }
}
.main-title.type-2 {
  font-family: $font-family-quicksand-title/* $font-family-roboto */;
  font-weight: 600;
  @include font-size-36();
  text-transform: $text-transform-site;
  color: $color-18;
  letter-spacing: 0.1px;
}
.main-title.small-title {
  font-family: $font-family-quicksand-title/* $font-family-roboto */;
  font-style: normal;
  font-weight: 600;
  @include font-size-24();
  color: $black-color-pure;
  letter-spacing: 0.1px;
  padding: 0;
  &::after,
  .main-title-icon {
    display: none;
  }
}
.title-with-bg-active-color {
  background: $color-22;
  border-radius: 15px 15px 0px 0px;
  font-family: $font-family-quicksand-title/* $font-family-roboto */;
  font-style: normal;
  font-weight: 600;
  @include font-size-30();
  align-items: center;
  text-align: center;
  text-transform: $text-transform-site;
  color: $active-color;
  letter-spacing: 0.5px;
  width: 90%;
  padding: 15px;
  &::after,
  .main-title-icon {
    display: none;
  }
}
.title-active-color {
  font-family: $font-family-quicksand-title/* $font-family-roboto */;
  font-style: normal;
  font-weight: 600;
  @include font-size-30();
  align-items: flex-start;
  text-align: left;
  text-transform: $text-transform-site;
  color: $color-18;
  letter-spacing: normal /* 0.5px */;
  padding: 0;
  &::after,
  .main-title-icon {
    display: none;
  }
}
.filter-single-container {
  gap: 10px;
  overflow: auto;
  .download-type-icon-text {
    font-family: $font-family-mulish-text/* $font-family-roboto */;
    font-style: normal;
    font-weight: 500;
    @include font-size-14();
    color: $black-color-pure;
    text-align: center;
    margin-top: 5px;
    word-break: break-all;
  }
  .filter-single {
    width: 80px;
    height: 80px;
    padding: 0 18px;
    border-radius: 50%;
    background: $white-color;
    border: 1px solid $color-20;
    transition: 0.2s all;
    min-width: 80px;
    &:hover {
      background: $active-color;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
      border-color: $active-color;
      img {
        filter: invert(100%);
      }
      small {
        color: $white-color;
      }
    }
    img {
      max-height: 40px;
      path {
        fill: $black-color-pure;
      }
    }
  }
  .filter-single.active {
    background: $active-color;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
    border-color: $active-color;
    img {
      filter: invert(100%);
    }
    small {
      color: $white-color;
    }
  }
}
.filter-container-2 {
  gap: 15px;
  overflow: auto;
  .filter-single {
    @include border-with-bg-radius($color-20, $white-color, 10px);
    font-family: $font-family-mulish/* $font-family-roboto */;
    font-style: normal;
    font-weight: 500;
    @include font-size-22();
    color: $black-color-pure;
    padding: 10px 20px;
    text-transform: $text-transform-site;
    cursor: pointer;
    transition: 0.2s all;
    /* min-width: 135px; */
    align-items: center;
    white-space: nowrap;
    &:hover {
      background-color: $color-20;
    }
  }
  .filter-single.active {
    background-color: $color-20;
  }
  .filter-single-v2 {
    &:hover {
      background: $color-4;
      color: $white-color;
    }
  }
}
.live-darshan-section {
  .live-darshan-single {
    position: relative;
    &::after {
      content: "";
      position: absolute;
      top: 1%;
      left: calc(0px + 12%);
      right: calc(0px + 12%);
      bottom: 0px;
      background: $color-4;
      border: 1px solid #f1decd;
      border-radius: 10px;
      transition: 0.2s all;
    }
    &.active,
    &:hover {
      &::after {
        /* top: calc(0px - 1.5rem); */
        left: 0;
        right: 0;
      }
    }
    /* iframe{
      min-height: 460px;
    } */
  }
  .live-darshan-single-a {
    z-index: 1;
    img {
      border-radius: 15px;
    }
  }
  .live-darshan-text {
    font-family: $font-family-mulish-text/* $font-family-roboto */;
    font-style: normal;
    font-weight: 700;
    @include font-size-22();
    color: $white-color;
  }
}
.live-darshan-section-2 {
  .live-darshan-single {
    transition: 0.2s all;
    .live-darshan-text {
      font-family: $font-family-mulish-text/* $font-family-roboto */;
      font-style: normal;
      font-weight: 600;
      @include font-size-22();
      color: $black-color-pure;
    }
    &:hover {
      .live-darshan-text {
        color: $color-18;
      }
    }
  }
}
.darshan-timings {
  background: #f5f5f5;
  thead {
    position: relative;
    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      border-bottom: 2px dashed $color-4;
    }
    td {
      font-family: $font-family-quicksand-title;
      font-style: normal;
      font-weight: 600;
      @include font-size-18();
      color: $color-18;
      border: none;
    }
  }
  table {
    td {
      padding: 15px;
    }
    tbody {
      border-top: none !important;
      td {
        font-family: $font-family-mulish-text/* $font-family-roboto */;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        @include font-size-18();
        color: $black-color-pure;
      }
    }
  }
}
.download-ringtone {
  align-items: center;
  padding-bottom: 75px !important;
  gap: 10px;
  .download-ringtone-data {
    font-weight: 500;
    color: $active-color;
    i {
      @include font-size-16();
      color: #555555;
      font-weight: normal;
      font-style: normal;
    }
  }
}
.download-single {
  &::after {
    position: absolute;
    content: "";
    bottom: -75px;
    left: 0;
    width: 100%;
    height: 75px;
    background: linear-gradient(
      180deg,
      rgba(196, 196, 196, 0) 0%,
      $orange-color 83.65%
    );
    background-blend-mode: darken;
    z-index: 0;
    transition: 0.1s all;
  }
  &:hover {
    &::after {
      bottom: 0px;
    }
    .download-count {
      bottom: 15px;
    }
    .download-type {
      bottom: 12px;
    }
  }
}

.download-count {
  position: absolute;
  bottom: -35px;
  left: 0px;
  width: 95px;
  height: 30px;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  z-index: 1;
  transition: 0.2s all;
  .icon {
    background-color: $active-color;
    img {
      width: 55%;
    }
  }
  .no-of-download {
    font-family: $font-family-mulish/* $font-family-roboto */;
    font-style: normal;
    font-weight: 700;
    @include font-size-18();
    text-transform: $text-transform-site;
    color: $black-color-pure;
    line-height: 0;
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: 100%;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}
/* Style your items */
.my-masonry-grid_column > div {
  /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 30px;
}

.download-type {
  position: absolute;
  bottom: -40px;
  right: 10px;
  gap: 5px;
  z-index: 1;
  transition: 0.2s all;
  .download-type-single {
    width: 40px;
    height: 40px;
    padding: 0 8px;
    border-radius: 50%;
    background: $white-color;
    border: 1px solid $color-20;
    transition: 0.2s all;
    &:hover {
      background: $active-color;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.25);
      border-color: $active-color;
      cursor: pointer;
      img {
        filter: invert(100%);
      }
    }
    img {
      max-height: 25px;
      path {
        fill: $black-color-pure;
      }
    }
  }
}
.map {
  overflow: hidden;
  height: 480px;
}
.mandir-single {
  .mandir-inner {
    /* gap: 15px; */
  }
  .mandir-content {
    gap: 10px;
  }
  .mandir-single-title {
    font-family: $font-family-quicksand-title/* $font-family-roboto */;
    font-style: normal;
    font-weight: 700;
    @include font-size-18();
    text-transform: $text-transform-site;
    color: $black-color-pure;
    margin: 0;
    letter-spacing: 0.1px;
  }
  .address {
    font-family: $font-family-mulish-text/* $font-family-roboto */;
    font-style: normal;
    font-weight: 400;
    @include font-size-18();
    @include line-height-26();
    color: $black-color-pure;
    margin: 0;
    letter-spacing: 0.1px;
    & * {
      margin: 0;
    }
  }
  .contacts {
    font-family: $font-family-mulish-text/* $font-family-roboto */;
    font-style: normal;
    font-weight: 400;
    @include font-size-18();
    color: $black-color-pure;
    gap: 15px;
  }
  .social-icons {
    gap: 10px;
    align-items: center;
  }
}
.mandir-nearme {
  position: relative;
  .mandir-nearme-bg {
    position: absolute;
    top: 27px;
    left: 5%;
    width: 90%;
    height: 75%;
    transform: translate(0%, 0%);
    background: $color-22;
    z-index: 0;
  }
  .title-box {
    z-index: 1;
  }
  .title {
    font-family: $font-family-quicksand-title/* $font-family-roboto */;
    font-style: normal;
    font-weight: 500;
    @include font-size-24();
    text-transform: $text-transform-site;
    color: $black-color-pure;
    letter-spacing: 0.1px;
  }
  .mandir-container-o {
    z-index: 1;
  }
}
.sayings {
  background: $color-6;
  overflow: initial;
}
.guruparampara {
  background: url("../../../public/img/gradient.png");
  background-size: cover;
  .guruparampara-box {
    margin: 40px 0 0 0;
    .guruparampara-image {
      position: relative;
      width: 60%;
      &::after {
        position: absolute;
        content: "";
        /* background: url("../../../public/img/Frame.svg"); */
        top: -8%;
        left: -8%;
        bottom: -8%;
        right: -8%;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
      img {
      }
    }
    .guruparampara-text {
      margin: 20px/* 40px */ 0 0 0;
      color: $black-color-pure;
      font-family: $font-family-mulish-text/* $font-family-roboto */;
      text-transform: $text-transform-site;
      font-weight: 500;
      width: 65%;
      word-break: break-word;
      @include font-size-22;
    }
  }
}
#main .guruparampara-box-tooltip,
.site-contain .guruparampara-box-tooltip,
.guruparampara-box .guruparampara-box-tooltip {
  padding: 0;
  background: $white-color;
  border: 4px solid $active-color;
  color: $black-color-pure;
  width: 500px;
  border-radius: 15px;
  opacity: 1 !important;
  box-shadow: 0px 2px 15px rgb(0 0 0 / 10%);
  @media (max-width: 500px) {
    width: 90%;
    left: 5px !important;
  }
  .guruparampara-box-tooltip-desc {
    font-family: $font-family-mulish-text/* $font-family-roboto */;
    font-style: normal;
    font-weight: 400;
    @include font-size-14();
    line-height: 16px;
    text-align: justify;
    color: $black-color-pure;
    margin: 0;
  }
  .guruparampara-box-tooltip-view-more {
    font-family: $font-family-mulish-text/* $font-family-roboto */;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $dark-active-color;
  }
  .tooltip-view-more-a {
    gap: 5px;
  }
}

#main .guruparampara-box-tooltip.place-top,
.site-contain .guruparampara-box-tooltip.place-top {
  &::after {
    border-top-color: $active-color;
    bottom: -23px;
    /* border-width: 16px; */
    margin-left: -16px;
  }
}
#main .guruparampara-box-tooltip.place-bottom,
.site-contain .guruparampara-box-tooltip.place-bottom {
  &::after {
    border-bottom-color: $active-color;
    top: -23px;
    /* border-width: 16px; */
    margin-left: -16px;
  }
}
#main .guruparampara-box-tooltip.place-right,
.site-contain .guruparampara-box-tooltip.place-right {
  &::after {
    border-right-color: #ee7576;
    left: -23px;
    /* border-width: 16px; */
    margin-top: -16px;
  }
}
#main .guruparampara-box-tooltip.place-left,
.site-contain .guruparampara-box-tooltip.place-left {
  &::after {
    border-left-color: $active-color;
    right: -23px;
    /* border-width: 16px; */
    margin-top: -16px;
  }
}
#main .guruparampara-box-tooltip,
.site-contain .guruparampara-box-tooltip {
  &::after {
    width: 50px;
    height: 50px;
    transform: rotate(45deg);
    border: 8px solid $active-color;
    background: $active-color;
    box-shadow: none;
    z-index: -1;
    backface-visibility: hidden;
  }
}
.tooltip-view-more-dot {
  font-size: 8px;
}
.big-separator {
  background: $active-color;
  height: 12px;
  margin-left: -1.5rem !important;
  margin-right: -1.5rem !important;
}
.dashed-separator {
  height: 2px;
  border-bottom: 1px dashed $active-color;
}
.event-details-nav {
  font-family: $font-family-mulish-text/* $font-family-roboto */;
  font-style: normal;
  font-weight: 500;
  @include font-size-20();
  text-transform: $text-transform-site;
  color: $black-color-pure;
  &:hover {
    color: $active-color;
  }
}
.event-details-nav.next {
  &::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: middle;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    transform: rotate(-90deg);
    margin-top: -2px;
  }
}
.event-details-nav.prev {
  &::before {
    display: inline-block;
    margin-right: 0.255em;
    vertical-align: middle;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
    transform: rotate(90deg);
    margin-top: -2px;
  }
}
.event-details {
  .event-days-details {
    gap: 15px;
  }
  .event-details-title {
    font-family: $font-family-quicksand-title/* $font-family-roboto */;
    font-style: normal;
    font-weight: 500;
    @include font-size-24();
    color: $dark-active-color;
  }
  .event-details-time {
    font-family: $font-family-mulish-text/* $font-family-roboto */;
    font-style: normal;
    font-weight: 400;
    @include font-size-18();
    color: #555555;
  }
  .event-details-days-desc {
    font-family: $font-family-mulish-text/* $font-family-roboto-slab */;
    font-style: normal;
    font-weight: 400;
    @include font-size-18();
    text-align: justify;
    color: $black-color-pure;
  }
}
.they-say-carousel {
  /* padding: 40px 0 0 0; */
  /* margin: 0 auto; */
  margin: 40px -10px 0;
  .slick-slide {
    padding: 0 10px;
  }
  .they-say-items {
    // height: 200px;
    border-radius: 15px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    position: relative;
  }
  .they-say-text {
    position: absolute;
    width: 75%;
    left: 0;
    bottom: 15px;
    height: auto;
    background: rgba(255, 255, 255, 0.85);
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    .they-say-sub-title {
      text-transform: $text-transform-site;
      margin: 0;
      padding: /* 6px 13px */18px 20px;
      @include font-size-18-v1();
      color: $black-color-pure;
      font-weight: 600;
      line-height: normal;
    }
  }
  .they-say-text.type-2 {
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #722d00 100%
    );
    width: 100%;
    bottom: 0;
    .they-say-sub-title {
      font-family: $font-family-mulish-text/* $font-family-roboto */;
      font-weight: 800;
      @include font-size-22();
      color: $white-color;
    }
  }
}
.news-image-text {
  .they-say-text {
    width: 70%;
    .they-say-sub-title {
      @include font-size-16();
    }
  }
}
.live-darshan {
  background: $color-6;
  .Flower-top {
    position: absolute;
    right: 0;
    width: 12vw;
    z-index: 1;
  }
  .container {
  }
  .live-darshan-box-container {
    border-radius: 15px 15px 0 0;
    margin: 0;
  }
  .live-darshan-box {
    padding: 50px 30px;
    position: relative;
    .title {
      font-style: normal;
      font-weight: 600;
      @include font-size-20();
      line-height: normal;
      color: $color-1;
      text-align: center;
      padding: 25px 0 35px;
      position: relative;
      text-transform: $text-transform-site;
      font-family: $font-family-quicksand-title/* $font-family-roboto */;
      letter-spacing: initial;
      &:after {
        position: absolute;
        content: "";
        left: 0%;
        bottom: 0;
        /* transform: translate(-50%, -50%); */
        width: 40%;
        height: 3px;
        background: $color-3;
      }
    }
    img {
      width: 100px;
      margin: 0 auto;
    }
    .sub-title {
      font-style: normal;
      font-weight: 600;
      @include font-size-18();
      line-height: 1.5;
      color: $color-2;
      text-align: center;
      margin: 20px 0 5px 0;
      font-family: $font-family-quicksand-title/* $font-family-roboto-slab */;
    }
    .description {
      text-align: center;
      color: $color-2;
      font-family: $font-family-mulish-text/* $font-family-roboto-slab */;
      font-weight: normal;
      @include font-size-16();
      line-height: 1.7;
    }
    .divider {
      position: absolute;
      top: 13%;
      right: -1px;
      width: 2px;
      height: calc(100% - 26%);
      span {
        margin-bottom: 10px;
        background: $orange-color;
        height: 30px;
      }
    }
  }
}
.live-broadcast {
  font-family: $font-family-quicksand/* $font-family-roboto-slab */;
  color: $white-color;
  .live-broadcast-box {
    padding: 0;
    background: $color-4;
    display: flex;
    align-items: center;
    img {
      object-fit: cover;
    }
    .live-broadcast-quote {
      padding: 0 35px;
    }
    .quote_board_slider {
      .slick-dots {
        bottom: 0px;
      }
    }
  }
  .title {
    color: $white-color;
    @include font-size-26();
    line-height: 1.3;
    text-transform: unset;
    letter-spacing: 0.5px;
    font-weight: 500;
  }
  .sub-title {
    margin: 15px 0 0 0;
    @include font-size-17();
    letter-spacing: 0.2px;
  }
}
.aboutus {
  background: $color-5;
  position: relative;
  padding: 120px 0;
  .container {
    position: relative;
    z-index: 2;
  }
  .Flower-top {
    position: absolute;
    left: 0;
    width: 12vw;
    top: 20%;
    z-index: 1;
  }
  .aboutus-box {
    background: $white-color;
    padding: 45px 30px 45px 30px;
    border-radius: 10px;
    margin: 55px 0 0 0;
    height: calc(100% - 55px);
    transition: 0.2s all;
    &:hover {
      box-shadow: 3px 2px 15px rgb(0 0 0 / 10%);
    }
    img {
      width: 90px;
      padding: 20px 0 20px;
    }
    .title {
      @include font-size-30();
      font-family: $font-family-quicksand-title/* $font-family-futura */;
      text-transform: $text-transform-site;
      color: $black-color-pure;
      position: relative;
      margin: 0 0 25px 0;
      padding: 25px 0 15px 0;
      letter-spacing: inherit;
      font-weight: 600;
      &:after {
        position: absolute;
        content: "";
        left: 50%;
        bottom: 0;
        transform: translate(-50%, -50%);
        width: 40%;
        height: 3px;
        background: $color-3;
      }
    }
  }
  .title {
    @include font-size-36();
    font-family: $font-family-quicksand-title/* $font-family-roboto-slab */;
    text-transform: $text-transform-site;
    font-weight: 700;
    color: $black-color-pure;
    position: relative;
    letter-spacing: -1.1px;
  }
  .sub-title {
    font-family: $font-family-futura;
    text-transform: $text-transform-site;
    font-size: 20px;
    color: $black-color-pure;
    font-weight: 600;
    position: relative;
    margin: 0 0 12px 0;
    padding: 0 0 15px 0;
    &:after {
      position: absolute;
      content: "";
      left: 0;
      bottom: 0;
      width: 18%;
      height: 3px;
      background: $color-3;
    }
  }
  .description {
    /* font-family: $font-family-roboto-slab; */
    font-family: $font-family-mulish-text/* $font-family-roboto */;
    line-height: 1.3;
    font-weight: 400;
    /* text-indent: 8%; */
    @include font-size-20();
  }
  .about-desc {
    @include font-size-24();
    letter-spacing: normal;
    color: $black-color-pure;
    font-weight: 200;
  }
}
/* .slick-initialized .slick-active.slick-cloned{
  display: none;
} */
.slick-slider .slick-track {
  margin-left: 0;
  margin-right: 0;
}
.news {
  background: $color-6;
  .news-carousel {
    /* padding: 35px 0 0 0; */
    margin: 35px -15px 0;
    .owl-prev,
    .owl-next,
    .slick-prev,
    .slick-next {
      top: 30%;
      transform: translate(-50%, 0%);
    }
  }
  .news-items {
    padding: 10px 15px 10px 15px;
    // margin: 20px 0 0 0;
    a {
      &:hover {
        background: $white-color;
      }
    }
    .news-items-data {
      transition: 0.2s all;
      &:hover {
        background: $white-color;
        box-shadow: 0px 0px 14px rgb(0 0 0 / 15%);
        border-radius: 15px;
      }
      img {
        border-radius: 15px;
      }
      &.horizontal:hover {
        background: transparent;
        box-shadow: none;
      }
      &.horizontal {
        .news-text {
          .description:hover {
            color: $dark-active-color;
          }
        }
      }
      .news-text {
        padding: 20px 15px 15px 15px;
        .news-posted-by {
          font-family: $font-family-mulish-text/* $font-family-roboto */;
          @include font-size-14();
          color: $black-color-pure;
        }
        .news-time {
          color: $color-7;
        }
        .description {
          text-transform: $text-transform-site;
          font-family: $font-family-quicksand-title;
          font-weight: 600;
          margin: 15px 0 0 0;
          text-align: left;
          color: $black-color-pure;
          word-wrap: break-word;
          width: 100%;
          @include font-size-20();
        }
      }

      .news-social {
        .st-inline-share-buttons{
          @media (max-width: 500px) {
            flex-direction: row;
          }
        }
        .st-has-labels {
          .st-btn {
            min-width: auto !important;
          }
          .st-label {
            display: none !important;
          }
        }
      }
    }
  }
}
.news-list {
  gap: 30px 0;
  .news-items {
    .news-items-data {
      .news-text {
        .news-list-divider {
          border-bottom: 1px solid $color-4;
          background-color: transparent;
        }
        .description {
          text-transform: none;
          line-height: 1.2em;
          height: 2.4em;
        }
        .summary {
          font-family: $font-family-mulish-text/* $font-family-roboto */;
          font-style: normal;
          font-weight: 400;
          @include font-size-18();
          text-align: justify;
          color: $color-17 /* $color-18 */;
          margin: 0;
          line-height: 1.4em;
          height: 5.6em;
        }
      }
    }
  }
}
.site-date-pa {
  position: absolute;
  bottom: 15px;
  background: rgba(255, 255, 255, 0.85);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
  padding: 5px 10px;
  border-left: 3px solid $active-color;
  z-index: 9;
  .site-date-pa-day {
    font-family: $font-family-quicksand/* $font-family-roboto */;
    font-style: normal;
    font-weight: 700;
    @include font-size-26();
    text-transform: $text-transform-site;
    color: $black-color-pure;
  }
  .site-date-pa-month,
  .site-date-pa-year {
    font-family: $font-family-quicksand/* $font-family-roboto */;
    font-style: normal;
    font-weight: 600;
    font-size: 11px;
    line-height: 12px;
    text-transform: $text-transform-site;
    color: $black-color-pure;
  }
}
.sidebar {
  .title {
    font-family: $font-family-quicksand-title/* $font-family-roboto */;
    font-style: normal;
    font-weight: 500;
    @include font-size-24();
    color: $active-color;
    letter-spacing: 0.5px;
    margin: 0;
    text-align: center;
    border-bottom: 1px dashed $color-4;
  }
  .gallery-sidebar {
    gap: 20px 0;
    img {
      border-radius: 5px;
      transition: 0.2s all;
      &:hover {
        box-shadow: 0px 0px 14px rgb(0 0 0 / 25%);
      }
    }
  }
  .sidebar-listing {
    .popular-news-details {
      border-bottom: 1px dotted $color-4;
      &:last-child {
        border-bottom: none;
      }
      a {
        font-family: $font-family-mulish-text/* $font-family-roboto */;
        font-style: normal;
        font-weight: 500;
        @include font-size-16()/* font-size-18() */;
        color: $black-color-pure;
        transition: 0.2s all;
        &:hover {
          color: $active-color;
        }
      }
      .popular-news-icon * {
        fill: $dark-active-color;
      }
    }
  }
}
.calender {
  background: $color-6;
  .nav-tabs {
    border: none;
    .nav-item {
      margin-right: 10px;
    }
    .nav-link {
      text-transform: $text-transform-site;
      @include font-size-24-v1();
      color: $active-color;
      font-family: $font-family-quicksand-title/* $font-family-roboto */;
      font-weight: 600;
      border: none;
      border-bottom: 2px solid transparent;
      padding: 7px;
      line-height: normal;
    }
    .nav-link.active,
    .nav-link:hover {
      background: none;
      color: $black-color-pure;
      border: none;
      border-bottom: 3px solid $active-color;
    }
  }
}
.calender-tab-content {
  padding: 20px 0 0 0;
}
.calender-box {
  .calender-dates {
    background: $color-8;
    padding: 15px;
    box-shadow: 3px 0px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px 0px 0px 10px;
    color: $black-color-pure;
    @include font-size-20();
    text-transform: $text-transform-site;
    font-weight: 500;
    z-index: 1;
    font-family: $font-family-mulish-text;
    .calender-dates-1 {
      @include font-size-18-v2();
      margin-bottom: 12px;
      line-height: normal;
    }
    .calender-dates-2 {
      font-weight: 600;
      position: relative;
      border-bottom: 2px solid $top-bar-color-text;
      margin: 0 0 3px 0;
      padding: 0 0 3px 0;
    }
    .calender-dates-3 {
      font-weight: 600;
    }
  }
  .calender-text {
    background: $white-color;
    border: 1px solid $color-9;
    box-sizing: border-box;
    border-radius: 0 10px 10px 0;
    text-align: left;
    padding: 15px 20px;
    border-left: none;
    color: $black-color-pure;
    font-size: 15px;
    font-weight: 500;
    height: 100%;
    position: relative;
    justify-content: center !important;
    &::before {
      position: absolute;
      content: "";
      top: -14px;
      left: 0;
      height: 16px;
      width: 18px;
      /* background: url(../../../public/img/Vector20.svg); */
      background-repeat: no-repeat;
      background-size: 100% 100%;
      z-index: -1;
    }
    &::after {
      position: absolute;
      content: "";
      bottom: -14px;
      left: 0;
      height: 17px;
      width: 18px;
      /* background: url(../../../public/img/Vector21.svg); */
      background-repeat: no-repeat;
      background-size: 100% 100%;
      z-index: -1;
    }
    .calender-icon {
      position: absolute;
      top: 5px;
      right: 10px;
      width: 25px;
    }
    .calender-tithi {
      @include /* font-size-17() */ font-size-16();
      font-family: $font-family-mulish;
      margin-bottom: 5px;
      color: $grey-color;
    }
    .calender-desc {
      line-height: normal;
      font-family: $font-family-quicksand;
      font-weight: 600;
      @include font-size-18() /* font-size-22-v1() */;
    }
  }
}
@mixin calendar-tooltip($aa:'') {
  .fc-event-tooltip {
    visibility: hidden;
    max-width: 300px;
    min-width: 250px;
    background-color: $white-color;
    color: $black-color;
    text-align: left;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    font-size: 11px;
    box-shadow: $btn-box-shadow;
    z-index: 9999;
    font-weight: 500;
    .fc-event-tooltip-title{
      @include font-size-13();
      font-weight: 600;
    }
    i{font-size: 10px;position: relative;font-style: normal;}
    &::after{
      content: "";
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: $white-color transparent transparent transparent;
    }
    a{
      transition: none;
    }
  }
  &:hover{
    .fc-event-tooltip {
      visibility: visible;
    }
  }
}
.calendar-sidebar {
  border-top: 10px solid $color-18;
  padding: 15px 7px;
  gap: 10px;
  .calendar-title-date {
    font-family: $font-family-quicksand-title/* $font-family-roboto */;
    font-style: normal;
    font-weight: 700;
    @include font-size-24();
    text-transform: $text-transform-site;
    color: $color-18;
    margin: 0;
  }
  .btn-calendar-sidebar {
    font-family: $font-family-quicksand/* $font-family-roboto */;
    font-style: normal;
    font-weight: 700;
    @include font-size-14();
    text-transform: $text-transform-site;
    color: $white-color;
    height: min-content;
    padding: 7px 15px;
  }
  .btn-calendar-sidebar-events {
    border-radius: 5px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    color: $white-color;
  }
  .calendar-title-guj-date {
    font-family: $font-family-quicksand-title/* $font-family-roboto */;
    font-style: normal;
    font-weight: 600;
    @include font-size-16()/* font-size-18() */;
    text-transform: $text-transform-site;
    color: $black-color-pure;
    margin: 0;
  }
  .event-details-nav {
    @include font-size-14();
  }
  .calendar-event-sidebar {
    padding: 8px 12px;
    border-left-width: 4px !important;
    gap: 10px;
    cursor: pointer;
    position: relative;
    @include calendar-tooltip();
    .event-day-title {
      /* border-bottom: 1px solid #515151; */
    }
    .event-sidebar-title {
      font-weight: normal;
      @include font-size-13()/* font-size-16() */;
      padding-left: 15px;
      position: relative;
      cursor: pointer;
      color: $dark-active-color;
      &:hover{
        color: $grey-color;
      }
      i {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 35px;
        font-style: normal;
        line-height: 20px;
      }
      @include calendar-tooltip();
    }
    .calendar-event-sidebar-title {
      font-family: $font-family-mulish-text/* $font-family-roboto */;
      font-style: normal;
      font-weight: 700;
      @include font-size-14()/* font-size-18() */;
      color: $black-color-pure;
      align-items: center;
      gap: 10px;
    }
    .calendar-event-sidebar-time {
      font-family: $font-family-mulish-text/* $font-family-roboto */;
      font-style: normal;
      font-weight: 400;
      @include font-size-14();
      color: #555555;
    }
    .calendar-event-sidebar-desc {
      font-family: $font-family-mulish-text/* $font-family-roboto */;
      font-style: normal;
      font-weight: 400;
      @include font-size-14();
      text-align: justify;
      color: $black-color-pure;
      margin: 0;
    }
  }
}
.sidebar-toggle{
  position: relative;
  padding-bottom: 10px;
  &::after{
    content: "";
    bottom: 0;
    left: -7px;
    right: -7px;
    height: 2px;
    position: absolute;
    border-bottom: 1px solid $color-28/* $color-20 */;
  }
  &:last-child{
    border-bottom: none;
    padding-bottom: 0px;
    &::after{
      content: "";
      bottom: 0;
      left: -7px;
      right: -7px;
      height: 0px;
      border-bottom: none;
    }
  }
  .sidebar-toggle-title{
    font-family: $font-family-quicksand-title/* $font-family-roboto */;
    font-weight: 500;
    @include font-size-16();
    cursor: pointer;
    border: 1px solid $color-20;
    padding: 2px 10px;
    border-radius: 10px;
  }
}
.global-network {
  background: $color-10;
  .global-network-box-o {
    // padding: 0 10px;
  }
  .global-network-box {
    background: $white-color;
    box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 15px 0;
    text-align: center;
    position: relative;
    height: 100%;
    transition: 0.2s all;
    img {
      width: 60%;
    }
    .global-network-text {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      text-transform: $text-transform-site;
      font-family: $font-family-mulish-text/* $font-family-roboto */;
      font-weight: 500;
      color: $color-11;
      @include font-size-38();
      width: 100%;
    }
    &:hover,
    &.active {
      background: $active-color;
      .global-network-text {
        text-shadow: 2px 2px 2px rgba(44, 44, 44, 0.5);
      }
    }
  }
}
.our-websites {
  background: $color-6;
  .website-carousel {
    /* padding: 40px 0 0 0; */
    margin: 40px -15px 0;
    .owl-prev,
    .owl-next,
    &.slick-slider .slick-next,
    &.slick-slider .slick-prev {
      top: 32%;
    }
  }
  .website-items {
    // margin: 20px 0 0 0;
    padding: 0 15px;
  }
  .description {
    text-transform: $text-transform-site;
    text-align: center;
    margin: 15px 0 0 0;
    transition: 0.3s all;
    font-family: $font-family-mulish;
    @include font-size-17-v2();
  }
  .website-items-data {
    border-radius: 15px;
    transition: 0.3s all;
    img {
      border-radius: 15px;
    }
    &:hover {
      .website-text {
        .description {
          color: $dark-active-color;
        }
      }
    }
  }
}

.btn-2 {
  background: $color-8;
  border-radius: 10px;
  font-weight: 700;
  @include font-size-22-v1();
  text-transform: $text-transform-site;
  color: $black-color-pure;
  font-family: $font-family-quicksand/* $font-family-roboto */;
  min-width: 160px;
  padding: 10px 20px;
  &:hover {
    background: $color-3;
    box-shadow: $btn-box-shadow;
    color: $white-color;
  }
}
.btn-3 {
  border: 1px solid $color-3;
  color: $black-color-pure;
  font-family: $font-family-quicksand/* $font-family-roboto */;
  //@include font-size-14();
  @include font-size-13();
  font-weight: 400;
  padding: 3px 5px;
  &:hover {
    .music-icon {
      fill: $white-color;
    }
    background: $color-3;
    box-shadow: $btn-box-shadow;
    color: $white-color;
  }
}
.btn-4 {
  background: $color-4;
  border-radius: 35px;
  font-family: $font-family-quicksand/* $font-family-roboto */;
  font-weight: 700;
  @include font-size-20();
  text-transform: $text-transform-site;
  color: $white-color;
  &:hover {
    .music-icon {
      fill: $white-color;
    }
    background: $color-3;
    box-shadow: $btn-box-shadow;
    color: $white-color;
  }
}

.btn-5 {
  background: $white-color;
  border-radius: 35px;
  font-family: $font-family-quicksand/* $font-family-roboto */;
  font-weight: 600;
  border: 4px solid $color-18;
  @include font-size-22();
  text-transform: $text-transform-site;
  color: $color-18;
  padding: 12px 20px /* 15px 25px */;
  svg {
    margin-right: 10px;
    fill: $color-18;
  }
  &:hover {
    svg {
      fill: $white-color;
    }
    background: $color-18;
    box-shadow: $btn-box-shadow;
    color: $white-color;
  }
}
.btn-6 {
  background: $color-4;
  border: 1px solid $color-20;
  border-radius: 15px;
  font-family: $font-family-quicksand/* $font-family-roboto */;
  font-weight: 700;
  @include font-size-20();
  text-transform: $text-transform-site;
  color: $white-color;
  padding: 15px 25px;
  svg {
    margin-right: 10px;
  }
  &:hover {
    svg {
      fill: $white-color;
    }
    background: $color-18;
    box-shadow: $btn-box-shadow;
    color: $white-color;
  }
}
.btn-7 {
  border: 1px solid $color-3;
  color: $white-color;
  background: $color-4;
  font-family: $font-family-quicksand/* $font-family-roboto */;
  //@include font-size-14();
  @include font-size-16();
  font-weight: 600;
  padding: 5px 10px;
  &:hover {
    .music-icon {
      fill: $white-color;
    }
    background: $color-3;
    box-shadow: $btn-box-shadow;
    color: $white-color;
  }
}
.btn-red {
  background: #ff0000;
  border: 1px solid #ff0000;
  border-radius: 4px;
  font-family: $font-family-mulish/* $font-family-roboto */;
  font-weight: 400;
  @include font-size-13();
  text-transform: $text-transform-site;
  color: $white-color;
  padding: 3px 10px;
  svg {
    margin-right: 10px;
  }
  &:hover {
    svg {
      fill: $white-color;
    }
    background: #be0a0a;
    box-shadow: $btn-box-shadow;
    color: $white-color;
  }
}
.btn-grey {
  background: $grey-color;
  border: 1px solid $grey-color;
  border-radius: 4px;
  font-family: $font-family-mulish/* $font-family-roboto */;
  font-weight: 400;
  @include font-size-13();
  text-transform: $text-transform-site;
  color: $white-color;
  padding: 3px 10px;
  svg {
    margin-right: 10px;
  }
  &:hover {
    svg {
      fill: $white-color;
    }
    background: $footer-color;
    box-shadow: $btn-box-shadow;
    color: $white-color;
  }
}
.btn-with-icon {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.track-play-btn {
  text-transform: none;
  @include font-size-16();
  padding: 7px 15px;
  border-width: 2px;
}

.publication-contain {
  background-color: $color-15;
  @include Flower-top();
  .publications {
    margin-top: 40px;
    .publication {
      background: $white-color;
      //border: 1px solid $color-26;
      border-radius: 10px;
      overflow: hidden;
      margin-top: 30px;
      position: relative;
      .publication-inner {
        min-height: 250px;
      }
      .publication-icon {
        position: absolute;
        height: 175px;
        width: 175px;
        display: flex;
        text-align: center;
        justify-content: center;
        padding: 15px;
        border-radius: 50%;
        background: $color-24;
        border: 8px solid $white-color;
        top: 50%;
        transform: translate(-50%, -50%);
        left: 25%;
        img {
          width: 60%;
          backface-visibility: visible;
        }
      }
      .publication-title {
        background: $color-8;
        @include font-size-36();
        color: $black-color-pure;
        font-family: $font-family-quicksand-title/* $font-family-futura-bt */;
        text-transform: $text-transform-site;
        font-weight: 600;
        letter-spacing: 0.5px;
        padding: 20px 90px /* 5vw */ 20px 20px;
        a {
          color: $black-color-pure;
          &:hover {
            color: $dark-active-color;
          }
        }
      }
      .publication-data {
        padding: 40px 30px 40px 120px;
        border: 1px solid $color-26;
        border-left: none;
        border-radius: 0 10px 10px 0;
        /* display: grid;
      grid-template-columns: repeat(4, 1fr); */
        .publication-data-inner {
          gap: 25px 45px;
          flex-wrap: wrap;
        }
      }
    }

    // for listing page
  }
}

.search-show {
  gap: 25px 45px;
  flex-wrap: wrap;
  .search-show-inner {
    gap: 25px 30px;
    flex-wrap: wrap;
    .filter-name {
      font-family: $font-family-mulish-text/* $font-family-roboto */;
      font-style: normal;
      font-weight: 600;
      @include font-size-20;
      color: $color-16;
      text-transform: $text-transform-site;
      align-items: center;
      display: flex;
    }
    .filter-name-single {
      font-weight: 400;
      text-transform: capitalize;
    }
    .filter-remove {
      font-size: 12px;
      margin-left: 5px;
      cursor: pointer;
      transition: 0.2s all;
      padding: 2px 4px;
      background: $color-16;
      color: $white-color;
      &:hover {
        background: $dark-active-color;
      }
    }
  }
}
.publication-data-container {
  margin-top: 40px;
  .publication-data {
    border: 1px solid $color-20;
    border-radius: 15px;
    background-color: $white-color;
    padding: 20px;
    .publication-image {
      filter: drop-shadow(0px 4px 4px $black-color-05-opacity);
      /* height: 190px; */
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
      overflow: hidden;
    }
    .publication-desc {
      font-family: $font-family-mulish-text/* $font-family-roboto-slab */;
      font-style: normal;
      font-weight: 400;
      @include font-size-14();
      color: $black-color-pure;
      margin: 10px 0 0 0;
      text-align: justify;
    }
    .publication-data-text {
      padding: 0 0 0 50px;
      position: relative;
      .publication-icon {
        position: absolute;
        top: 5px;
        left: 12px;
        /* width: 20px;
        height: 20px; */
        fill: $dark-active-color;
        stroke: $dark-active-color;
        * {
          fill: $dark-active-color;
          stroke: $dark-active-color;
        }
      }
      .publication-title {
        font-family: $font-family-quicksand-title/* $font-family-roboto */;
        font-style: normal;
        font-weight: 500;
        @include font-size-22-v1();
        color: $dark-active-color;
        line-height: 1.2;
        height: 2.4em;
      }
      .publication-types {
        font-family: $font-family-mulish-text/* $font-family-roboto */;
        font-style: normal;
        font-weight: 400;
        @include font-size-14();
        color: $color-2;
        margin: 10px 0;
        text-transform: capitalize;
      }
    }
    .st-inline-share-buttons {
      display: flex !important;
      flex-direction: row;

      .st-btn {
        display: flex !important;
        padding: 0 8px !important;
      }
    }
  }
}
.search-cms-data-container{
  .publication-data{
    border: none;
    border-radius: 0px;
    padding: 0;
    background-color: unset;
    .publication-data-text{
      border-bottom: 1px solid $color-10;
      &:last-child{
        border-bottom: none;
      }
      .publication-title{
        height: auto;
      }
    }
  }
  
}
.quiz-container {
  .quiz-data {
    cursor: pointer;
    position: relative;
    .quiz-book-seleted {
      position: absolute;
      top: 10px;
      right: 10px;
      z-index: 1;
      /* height: 0px;
      width: 0px; */
      opacity: 0;
      visibility: hidden;
      transition: 0.2s all;
      svg {
        fill: $dark-active-color;
        background-color: $white-color;
        border-radius: 50%;
      }
      &.active {
        height: auto;
        width: auto;
        opacity: 1;
        visibility: visible;
      }
    }
    .publication-data-text {
      .publication-title {
        @include font-size-16();
      }
    }
  }
}

.pagination-s {
  gap: 5px;
  .page-item {
    .page-link {
      background-color: transparent;
      border-color: transparent;
      color: $black-color-pure;
      opacity: 0.7;
      padding: 4px 12px;
      font-weight: 400;
      font-size: 18px;
      @include font-size-18-v1();
      font-family: $font-family-mulish/* $font-family-roboto */;
      border-width: 2px;
      &:focus {
        box-shadow: none /* 0 0 0 0.2rem rgb(176 163 120 / 25%) */;
      }
      &:hover {
        border: 2px solid $dark-active-color;
        border-radius: 5px;
        color: $black-color-pure;
        opacity: 1;
      }
    }
  }
  .active {
    .page-link {
      border: 2px solid $dark-active-color;
      border-radius: 5px;
      color: $black-color-pure;
      opacity: 1;
    }
  }
  .disabled {
    .page-link {
      opacity: 0.5;
      &:hover {
        cursor: not-allowed;
      }
    }
  }
}
.publication-details-sidebar-contain {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all;
  .publication-details-sidebar-close {
    color: $black-color-pure;
    cursor: pointer;
  }
  .publication-details-sidebar-link {
    color: $black-color-pure;
    transition: 0.2s all;
    position: relative;
    @include font-size-16()/* font-size-17() */;
    &:hover {
      color: $dark-active-color;
    }
    .dot {
      /* @include font-size-38();
      line-height: 0.7; */
      position: absolute;
      top: 9px /* 0.65rem */;
      left: -15px;
      height: 4px;
      width: 4px;
      background: $black-color-pure;
      border-radius: 50%;
    }
  }
  .publication-details-sidebar-link.active {
    color: $dark-active-color;
  }
  .publication-details-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    transition: 0.2s all;
  }
  .publication-details-sidebar {
    position: absolute;
    height: 100%;
    overflow: auto;
    width: 400px;
    top: 0;
    right: 0;
    background-color: $top-bar-color-light;
    padding: 15px;
    display: flex;
    flex-direction: column;
    transform: translate(100%, 0px);
    transition-property: transform;
    transition-duration: 0.3s;
    box-shadow: -10px 0px 20px rgb(0 0 0 / 10%);
    border-left: 2px solid $active-color;
    .publication-details-sidebar-title {
      @include font-size-20();
    }
  }
}
.publication-details-sidebar-contain.inactive {
  opacity: 0;
  visibility: hidden;
  .publication-details-overlay {
    display: none;
  }
  .publication-details-sidebar {
    transform: translate(100%, 0px);
  }
}
.publication-details-sidebar-contain.active {
  opacity: 1;
  visibility: visible;
  .publication-details-overlay {
    display: block;
  }
  .publication-details-sidebar {
    transform: translate(0%, 0px);
  }
}
.publication-details-container {
  background: $white-color;
  border: 1px solid $color-20;
  border-radius: 15px;
  margin-top: 20px;
  position: relative;
  .publication-details-pdf {
    border-bottom: 5px solid #2a2a2e;
    width: 100%;
    overflow: hidden;
  }
  .publication-details-container-inner {
    padding: 35px 50px 35px 35px;
  }
  .publication-play-btn {
    position: absolute;
    min-width: 170px;
    top: -15px;
    right: 50px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 25px;
    padding-right: 25px;
  }
  .publication-image {
    /* filter: drop-shadow(0px 4px 4px $black-color-05-opacity); */
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
    overflow: hidden;
    margin-top: -100px;
    border: 3px solid $active-color;
  }
  .publication-image-divider {
    height: 15px;
    border-radius: 0 0 10px 10px;
    background-color: $active-color;
    margin: 0 3%;
  }
  .publication-details-title {
    margin-top: -95px;
  }
  .publication-artist {
    font-family: $font-family-quicksand-title/* $font-family-roboto */;
    @include font-size-18();
    gap: 5px 25px;
    color: $color-17;
  }
}
.comma-after{
  &::after{
    content: ", ";
  }
  &:last-child{
    &::after{
      content: "";
    }
  }
}
.publication-synopsis {
  .publication-synopsis-title {
    font-family: $font-family-quicksand-title/* $font-family-roboto */;
    font-style: normal;
    font-weight: 500;
    @include font-size-16();
    text-align: justify;
    text-transform: $text-transform-site;
    color: $black-color-pure;
  }
  .publication-synopsis-desc {
    font-family: $font-family-mulish-text/* $font-family-roboto-slab */;
    font-style: normal;
    font-weight: 500;
    @include font-size-16();
    text-align: justify;
    color: $black-color-pure;
  }
}
.publication-track-table {
  font-family: $font-family-quicksand-title/* $font-family-roboto */;
  thead {
    position: relative;
    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      border-bottom: 2px dashed $color-4;
    }
    td {
      font-style: normal;
      font-weight: 600;
      @include font-size-18();
      color: $color-18;
      border: none;
    }
  }
  tbody {
    border-top: none !important;
    tr {
      transition: 0.2s all;
      &:hover {
        background: $top-bar-color-light /* rgba(255, 217, 184, 0.5) */;
        td {
          color: $black-color-pure;
        }
      }
      td {
        font-family: $font-family-mulish-text/* $font-family-roboto */;
        font-style: normal;
        font-weight: 500;
        @include font-size-18();
        color: #5a5a5a;
      }
    }
    tr.active {
      background: $top-bar-color-light /* rgba(255, 217, 184, 0.5) */;
      td {
        color: $black-color-pure;
      }
    }
  }
}
.publication-details-related {
  border-radius: 0 0 15px 15px;
  gap: 5px 0;
  overflow: hidden;
  .related-data {
    background: #f5f5f5;
    padding: 30px;
    width: 100%;
    .related-slider-inner {
      padding: 10px;
    }
    .related-data-carousel {
      width: 100%;
      .slick-prev,
      .slick-next {
        position: absolute;
        height: 30px;
        width: 30px;
        border: 2px solid rgba(0, 0, 0, 0.2);
        text-align: center;
        box-shadow: none;
        background-color: transparent !important;
        transition: 0.2s all;
        i {
          color: rgba(0, 0, 0, 0.2);
        }
        &::before {
          color: $black-color-pure;
        }
        &:hover {
          border-color: $black-color-pure;
          i {
            color: $black-color-pure;
          }
        }
      }
      .slick-next {
        right: 20px;
        top: -15px;
      }
      .slick-prev {
        top: -15px;
        right: 20px;
        left: auto;
      }
      .slick-track,
      .slick-list {
        display: flex;
        align-items: center;
      }
    }
    .related-title {
      font-family: $font-family-quicksand-title/* $font-family-futura-bt */;
      font-style: normal;
      font-weight: 400;
      font-size: 24px;
      @include font-size-24();
      text-transform: $text-transform-site;
      color: $black-color-pure;
      margin: 0;
    }
    .publication-related-image {
      border-radius: 10px;
    }
    .publication-related-name-1 {
      //font-family: $font-family-futura-bt;
      font-family: $font-family-mulish/* $font-family-roboto */;
      //font-weight: 400;
      font-weight: 500;
      @include font-size-16();
      text-transform: $text-transform-site;
      color: $black-color-pure;
      margin-bottom: 5px;
    }
    .publication-related-name-2 {
      font-family: $font-family-futura-bt;
      font-weight: 400;
      font-size: 18px;
      @include font-size-18();
      color: $color-18;
    }
    .related-nav {
      position: absolute;
      top: -40px;
      right: 0px;
      display: flex;
      gap: 0 10px;
      .owl-prev,
      .owl-next {
        position: relative;
        top: auto;
        left: auto;
        transform: translate(0, 0);
        height: 30px;
        width: 30px;
        border: 2px solid rgba(0, 0, 0, 0.2);
        text-align: center;
        box-shadow: none;
        background-color: transparent !important;
        transition: 0.2s all;
        i {
          color: rgba(0, 0, 0, 0.2);
        }
        &:hover {
          border-color: $black-color-pure;
          i {
            color: $black-color-pure;
          }
        }
      }
    }
  }
}

.site-contain {
  background-color: $color-15;
  @include Flower-top();
}
.description-2 {
  font-family: $font-family-mulish-text/* $font-family-roboto-slab */;
  font-style: normal;
  font-weight: 400;
  @include font-size-18();
  text-align: justify;
  color: $black-color-pure;
}

/**
Cms page start
*/
.image-text-row {
  img {
    z-index: 0;
    border-radius: 15px 15px 0 0;
  }
  .image-text-row-text {
    position: relative;
    font-family: $font-family-mulish-text/* $font-family-roboto */;
    font-style: normal;
    font-weight: 400;
    @include font-size-24();
    line-height: 1.2;
    text-align: justify;
    color: $white-color;
    /* background: linear-gradient(
      180deg,
      rgba(254, 140, 0, 0.9) 0%,
      rgba(248, 54, 0, 0.9) 193.33%
    ); */
    background: linear-gradient(
      180deg,
      $orange-color-opacity 0%,
      $dark-active-color 193.33%
    );
    padding: 20px;
    margin-top: -15px;
    z-index: 1;
    letter-spacing: 0.2px;
    &::after {
      content: "";
      position: absolute;
      height: 89%;
      width: 50%;
      bottom: 0;
      left: -10000%;
      background-color: transparent;
      transform: skew(60deg, 0deg);
    }
    * {
      margin: 0;
    }
  }
}
.cms-text-desc {
  font-family: $font-family-mulish-text/* $font-family-roboto */;
  font-style: normal;
  font-weight: $font-weight-normal;
  @include font-size-22();
  text-align: justify;
  color: $black-color-pure;
}
.cms-roboto-slab-desc {
  font-family: $font-family-roboto-slab;
  font-style: normal;
  font-weight: $font-weight-normal;
  @include font-size-24();
  color: $black-color-pure;
}
.shadow-bg-with-design,
.shadow-bg,
.white-bg-with-design-and-shadow {
  padding: 30px 45px;
  * {
    z-index: 2;
  }
}
.white-bg-with-design-and-shadow {
  background: $white-color;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
}
.image-caption {
  font-family: $font-family-mulish-text/* $font-family-roboto */;
  font-style: normal;
  font-weight: 400;
  @include font-size-20();
  color: $black-color-pure;
}

.list-text {
  ul {
    padding: 0 0 0 30px !important;
    li {
      /* font-family: $font-family-roboto-slab; */
      font-style: normal;
      font-weight: $font-weight-normal;
      @include font-size-22();
      text-align: justify;
      color: $black-color-pure;
      margin-bottom: 10px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 8px;
        left: -20px;
        height: 8px;
        width: 8px;
        background: $dark-active-color;
        transform: rotate(45deg);
      }
    }
  }
}

.white-bg-with-borders {
  .main-title-outer {
    margin-bottom: 0px !important;
  }
  .content {
    background: $white-color;
    border: 1px solid $color-20;
    border-radius: 15px;
    border-top-width: 8px;
    border-top-color: $active-color;
    padding: 35px 40px;
  }
  ul {
    padding: 0 0 0 30px;
    li {
      font-family: $font-family-mulish-text/* $font-family-roboto-slab */;
      font-style: normal;
      font-weight: $font-weight-normal;
      @include font-size-22();
      text-align: justify;
      color: $black-color-pure;
      margin-bottom: 10px;
      position: relative;
      &::after {
        content: "";
        position: absolute;
        top: 13px;
        left: -20px;
        height: 8px;
        width: 8px;
        background: $dark-active-color;
        transform: rotate(45deg);
      }
    }
  }
}
.no-bg-with-design-2,
.white-bg-with-design-and-shadow-quote {
  .content {
    padding: 35px 55px;
    position: relative;
    display: flex;
    /* background-image: url("../../../public/icons/border-design-2.svg");
    background-size: 100% 100%; */
    .row {
      z-index: 1;
    }
    .cms-text-desc {
      @include /* font-size-26() */ font-size-22();
      .cms-text {
        text-indent: 50px;
        position: relative;
        &::after,
        svg {
          content: "";
          position: absolute;
          top: 5px;
          left: 0;
          width: 40px;
          height: 40px;
          /* background-image: url("../../../public/icons/quote-start.svg"); */
        }
        svg {
          /* height: 100%;
          width: 100%; */
          * {
            fill: $active-color;
          }
        }
      }
      .quote-by {
        font-family: $font-family-mulish-text/* $font-family-roboto */;
        font-style: italic;
        font-weight: 400;
        @include font-size-20();
        text-align: justify;
        color: $color-17;
      }
    }
  }
}

.white-bg-with-design-and-shadow-quote {
  background: $white-color;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 0px 50px;
  .cms-text-desc {
    padding: 20px;
    font-weight: 300;
  }
  .top-left-design,
  .bottom-right-design {
    /* background-image: url("../../../public/icons/border-design-4.svg"); */
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  .top-left-design {
    top: 15px;
    left: 15px;
  }
  .bottom-right-design {
    bottom: 15px;
    right: 15px;
  }
}

.border-1 {
  position: absolute;
  top: 10px;
  left: 10px;
  height: calc(100% - 20px);
  width: calc(100% - 20px);
  overflow: hidden;
  z-index: 0;
  .border-inner {
    border: 2px solid $active-color;
    height: 100%;
    width: 100%;
    i {
      width: 66px;
      height: 66px;
      border: 2px solid $active-color;
      border-radius: 50%;
      background-color: $color-15;
      position: absolute;
    }
    .top {
      top: -33px;
    }
    .bottom {
      bottom: -33px;
    }
    .left {
      left: -33px;
    }
    .right {
      right: -33px;
    }
  }
}

.border-2 {
  position: absolute;
  top: 0px;
  left: 0px;
  height: calc(100%);
  width: calc(100%);
  overflow: hidden;
  z-index: 0;
  .border-inner {
    border: 3px solid $active-color;
    height: 100%;
    width: 100%;
    i {
      width: 68px;
      height: 68px;
      border: 3px solid $active-color;
      border-radius: 50%;
      background-color: $color-15;
      position: absolute;
      &::after {
        content: "";
        position: absolute;
        /* background-image: url("../../../public/icons/design-3.svg"); */
        height: 26px;
        width: 28px;
      }
      svg {
        position: absolute;
        /* background-image: url("../../../public/icons/design-3.svg"); */
        height: 26px;
        width: 28px;
        * {
          fill: $active-color;
          /* fill-opacity: 1; */
        }
      }
    }
    .top {
      top: -34px;
    }
    .bottom {
      bottom: -34px;
    }
    .left {
      left: -34px;
    }
    .right {
      right: -34px;
    }
    .top.left {
      &::after,
      svg {
        bottom: 3px;
        right: 3px;
      }
    }
    .bottom.left {
      &::after,
      svg {
        top: 3px;
        right: 3px;
        transform: rotate(-90deg);
      }
    }
    .top.right {
      &::after,
      svg {
        bottom: 3px;
        left: 3px;
        transform: rotate(90deg);
      }
    }
    .bottom.right {
      &::after,
      svg {
        top: 3px;
        left: 3px;
        transform: rotate(-180deg);
      }
    }
  }
}

.top-left-design {
  position: absolute;
  top: 0;
  left: 0;
  height: 150px;
  width: 150px;
  /* background-image: url("../../../public/icons/design.svg"); */
  z-index: 1;
  svg {
    height: 100%;
    width: 100%;
    * {
      fill: $active-color;
    }
  }
}
.bottom-right-design {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 150px;
  width: 150px;
  /* background-image: url("../../../public/icons/design.svg"); */
  transform: rotate(180deg);
  z-index: 1;
  svg {
    height: 100%;
    width: 100%;
    * {
      fill: $active-color;
    }
  }
}
.top-left-bg {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 60%;
  background: linear-gradient(
    145.92deg,
    $color-21 -10.9%,
    rgba(196, 196, 196, 0) 36.34%
  );
  z-index: 0;
}
.bottom-right-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  height: 100%;
  width: 60%;
  background: linear-gradient(
    145.92deg,
    $color-21 -10.9%,
    rgba(196, 196, 196, 0) 36.34%
  );
  transform: rotate(180deg);
  z-index: 0;
}

ul.site-tabs {
  gap: 10px;
  border-bottom: 5px solid $active-color;
  flex-wrap: nowrap;
  overflow: auto;
  li {
    margin: 0;
    padding: 10px 15px 5px;
    transition: 0.2s all;
    border-radius: 15px 15px 0px 0px;
    color: #545454;
    font-family: $font-family-mulish-text/* $font-family-roboto */;
    font-style: normal;
    font-weight: $font-weight-normal;
    @include font-size-18();
    cursor: pointer;
    a {
      color: #545454;
      font-family: $font-family-mulish-text/* $font-family-roboto */;
      font-style: normal;
      font-weight: $font-weight-normal;
      @include font-size-18();
    }
  }
  li.active,
  li:hover {
    background: $active-color;
    color: $white-color;
    a {
      color: $white-color;
    }
  }
}
.tab-content {
  /* border: 2px solid $orange-color; */
  border-radius: 0px 0px 10px 10px;
  .tab-content-inner {
    color: $black-color-pure;
  }
}
.tab-panel-container {
  .tab-content {
    border: 2px solid $active-color;
  }
}
/**
Cms page end
*/

/* timeline start here */

@mixin timeline-colors($main-color: #00c0c0, $text-color: $white-color) {
  $timline-margin-bottom-content: 30px;
  .day-text {
    left: -180px;
  }
  .day-text-month {
    padding: 4px 20px;
    background-color: $main-color;
    color: $text-color;
    font-family: $font-family-quicksand-title/* $font-family-roboto-slab */;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    @include font-size-20();
    text-align: center;
  }
  .day-text-day {
    font-family: $font-family-quicksand-title/* $font-family-roboto-slab */;
    font-style: normal;
    font-weight: 800;
    font-size: 40px;
    line-height: 48px;
    color: $main-color;
    text-align: center;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 50%;
      width: 80%;
      height: 2px;
      transform: translate(-50%, -50%);
      border-bottom: 1px dashed #c5c5c5;
    }
  }
  .day-text-gujarati {
    font-family: $font-family-mulish-text/* $font-family-roboto */;
    font-style: normal;
    font-weight: 400;
    @include font-size-14();
    line-height: 16px;
    color: #767676;
    text-transform: none;
    text-align: center;
    padding: 5px 0;
  }
  .panel {
    border: 1px solid $main-color;
    border-top-width: 15px;
    background: $white-color;
    border-radius: 15px;
    margin-bottom: $timline-margin-bottom-content;
    margin-left: 180px;
    &::after {
      background-color: $main-color;
      content: "";
      position: absolute;
      top: 5px;
      left: -108px;
      width: 2px;
      height: calc(100% + $timline-margin-bottom-content);
      transform: translate(-50%, 0);
      z-index: 0;
    }
  }
  /* h4.panel-title a:hover {
    color: $main-color;
  } */

  @media (max-width: 1024px) {
    .day-text {
      left: -165px;
    }
    .panel {
      margin-left: 165px;
      &::after {
        left: -95px;
      }
    }
  }
  @media (max-width: 768px) {
    .panel {
      margin-left: 0 /* 140px */;
      padding: 12px;
      &::after {
        left: -85px;
        display: none;
      }
      &::before {
        display: none;
      }
    }
    .day-text {
      left: auto /* -140px */;
      position: relative;
      flex-direction: row !important;
      justify-content: space-between;
      top: auto;
      margin-bottom: 15px;
      overflow: hidden;
      .day-text-day {
        &::after {
          display: none;
        }
      }
      .day-text-month,
      .day-text-gujarati {
        display: flex;
        justify-content: center;
        flex-direction: column;
        padding: 0 12px;
      }
      .day-text-gujarati {
        /* padding: 0px 20px; */
      }
    }
  }
}
.timeline {
  .panel-group-contain {
    &:last-child {
      .panel {
        &::after {
          display: none;
        }
      }
    }
  }
}
.timeline {
  position: relative;
  padding: 0;
  font-family: $font-family-mulish-text/* $font-family-roboto */;
}
.timeline .panel {
  width: auto;
  position: relative;
  clear: both;
  margin: 0 auto;
  padding: 25px 30px;
  background: none;
  min-height: 150px;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.timeline .glyphicon.glyphicon-one-fine-dot:before {
  /* content: "●";
  color: $active-color; */
}
.timeline .line {
  position: absolute;
  width: 2px;
  display: block;
  top: 25px;
  bottom: 20px;
  margin-left: 72px;
  background: $active-color;
}
.timeline .separator {
  border-top: 1px solid $active-color;
  padding: 5px;
  padding-left: 40px;
  font-style: italic;
  font-size: 0.9em;
  margin-left: 30px;
}
.timeline .line::before {
  top: -2px;
}
.timeline .line::after {
  bottom: -2px;
}
.timeline .line::before,
.timeline .line::after {
  content: "";
  position: absolute;
  left: -6px;
  width: 0;
  height: 0;
  display: block;
  border-radius: 50%;
  background: $active-color;
}
.timeline .panel::before {
  position: absolute;
  display: block;
  top: 15px;
  left: -32px;
  content: "";
  width: 0px;
  height: 0px;
  border: inherit;
  border-width: 16px;
  border-top-color: transparent;
  border-bottom-color: transparent;
  border-left-color: transparent;
}
.timeline .panel .panel-heading.icon * {
  font-size: 14px;
  vertical-align: middle;
  line-height: 40px;
}
.timeline .panel > .panel-heading .icon {
  position: absolute;
  left: -37px;
  width: 20px;
  height: 20px;
  padding: 0px;
  border-radius: 50%;
  text-align: center;
  top: 20px;
}
.timeline .panel:first-child .icon {
  top: 0px;
}
.timeline .panel-outline {
  border-color: transparent;
  background: transparent;
  box-shadow: none;
}
.timeline .panel-outline .panel-body {
  padding: 10px 0px;
}
.timeline .panel-body {
  /* display: none; */
}
.timeline .panel-default {
  border: 0;
}
.timeline .panel-default > .panel-heading {
  position: relative;
  border: 0;
  background: none;
  font-weight: 400;
}
.timeline .panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top: 0;
  padding: 0;
  border-bottom: 0;
}
.timeline
  .panel-default
  > .panel-heading
  + .panel-collapse
  > .panel-body
  .description {
  font-family: $font-family-mulish-text/* $font-family-roboto *//* $font-family-roboto-slab */;
  font-style: normal;
  font-weight: 400;
  @include /* font-size-22() */ font-size-18();
  text-align: justify;
  color: $black-color-pure;
  margin: 0;
  line-height: 1.5;
}
.timeline .description {
  font-family: $font-family-mulish-text/* $font-family-roboto */;
  font-style: normal;
  font-weight: 400;
  @include /* font-size-22() */ font-size-18();
  text-align: justify;
  color: $black-color-pure;
  margin: 0;
  line-height: 1.5;
}
.timeline h4.panel-title {
  border: 0;
  position: relative;
  padding: 0 15px 0 0;
  font-family: $font-family-quicksand-title/* $font-family-futura-bt */;
  font-style: normal;
  font-weight: 500;
  @include font-size-26();
  color: $black-color-pure;
  transition: 0.2s all;
  a {
    color: $dark-active-color;
    /* text-decoration: underline; */
  }
}
.timeline h4.panel-title a:hover {
  color: $black-color-pure;
}
.timeline .day-text {
  position: absolute;
  top: -15px;
  text-transform: $text-transform-site;
  font-size: 15px;
  font-weight: 500;
  color: $black-color-pure;
  background: #ffffff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  border-radius: 30px;
  overflow: auto;
  z-index: 1;
  min-width: 140px;
}
.timeline img.down-arrow {
  position: absolute;
  top: 5px;
  right: 0;
  width: 15px;
  transform: rotate(180deg);
  transition: 0.4s all;
}
.timeline .panel-title a[aria-expanded="true"] img.down-arrow {
  transform: rotate(0deg);
}
.accordion-option {
  position: absolute;
  top: 0;
  right: 15px;
}
.accordion-option .toggle-accordion:before {
  content: "Expand All";
}
.accordion-option .toggle-accordion.active:before {
  content: "Collapse All";
}
.timeline {
  @include timeline-colors($orange-color);
  .amethyst {
    @include timeline-colors(#a05dc9, $white-color);
  }
  .electric-purple {
    @include timeline-colors(#de168e, $white-color);
  }
  .tangerine {
    @include timeline-colors(#eb7f00, $white-color);
  }

  .alizarin {
    @include timeline-colors(#e02828, $white-color);
  }
  .golden-poppy {
    @include timeline-colors(#e2bf03, $white-color);
  }
  .citrus {
    @include timeline-colors(#83d103, $white-color);
  }
  .shamrock {
    @include timeline-colors(#27cb86, $white-color);
  }
  .is-green {
    @include timeline-colors(#17a20b, $white-color);
  }
  .pacific-blue {
    @include timeline-colors(#06a3b8, $white-color);
  }
  .navy-blue {
    @include timeline-colors(#0267c4, $white-color);
  }
  .egyptian-blue {
    @include timeline-colors(#0f16bc, $white-color);
  }
  .purple-heart {
    @include timeline-colors(#852aaf, $white-color);
  }
  .dark-magenta {
    @include timeline-colors(#b105a0, $white-color);
  }
  .old-rose {
    @include timeline-colors(#be2c46, $white-color);
  }
}
/* timeline ends here */

/*
Calenders starts
*/
.fc .fc-scrollgrid-section-sticky > *{
  z-index: unset !important;
}
.calendar-content {
  background-color: $color-27;
  padding: 7px;
  border-radius: 8px;
  border: 1px solid $color-28;
  .fc {
    /* background-color: $color-27;
    padding: 7px;
    border-radius: 8px;
    border: 1px solid $color-28; */
    .fc-daygrid-more-link{
      @include font-size-13();
    }
    .fc-daygrid-day-bottom{
      @include font-size-13();
    }
    .fc-popover{
      .fc-popover-title{
        @include font-size-20();
      }
      .fc-popover-close{
        @include font-size-18();
      }
      .fc-more-popover-misc{
        @include font-size-17();
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 15px;
        margin-bottom: 7px;
        .daycellDate{
          width: auto;
          height: auto;
        }
      }
    }
    .fc-scrollgrid,
    .fc-theme-standard {
      border: none;
    }
    .fc-scroller-harness{
      overflow:unset;
    }
    .fc-next-button.fc-button.fc-button-primary,
    .fc-prev-button.fc-button.fc-button-primary {
      background: transparent;
      border: none;
      font-family: $font-family-quicksand-title/* $font-family-roboto */;
      font-style: normal;
      font-weight: 600;
      @include font-size-20();
      text-transform: $text-transform-site;
      color: $black-color-pure;
      transition: 0.2s all;
      visibility: visible;
      &:focus {
        box-shadow: none;
      }
      &:hover {
        color: $dark-active-color;
      }
    }
    .fc-next-button.fc-button.fc-button-primary {
      &::after {
        display: inline-block;
        margin-left: 0.255em;
        vertical-align: middle;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        transform: rotate(-90deg);
        margin-top: -2px;
      }
    }
    .fc-prev-button.fc-button.fc-button-primary {
      &::before {
        display: inline-block;
        margin-right: 0.255em;
        vertical-align: middle;
        content: "";
        border-top: 0.3em solid;
        border-right: 0.3em solid transparent;
        border-bottom: 0;
        border-left: 0.3em solid transparent;
        transform: rotate(90deg);
        margin-top: -2px;
      }
    }
    .fc-toolbar.fc-header-toolbar {
      margin-bottom: 10px;
    }
    .fc-scrollgrid-liquid {
      border: none;
    }
    .fc-toolbar-title {
      background: $color-28 /* #ffccaa */;
      border-radius: 10px;
      padding: 12px 20px;
      font-family: $font-family-quicksand-title/* $font-family-roboto */;
      font-style: normal;
      font-weight: 600;
      @include font-size-24();
      text-transform: $text-transform-site;
      color: $black-color-pure;
      &::after {
        content: " - Fagan/Chaitra 2078 VS";
      }
    }
  }
  .fc-theme-standard {
    .fc-day-other{
      padding: 0;
      .fc-daygrid-day-frame{
        display: none;
      }
    }
    td,
    th {
      border: none;
      background: transparent;
    }
    th.fc-col-header-cell {
      .fc-scrollgrid-sync-inner {
        background: rgba(255, 255, 255, 0.5);
        border-radius: 5px 5px 0px 0px;
        font-family: $font-family-mulish-text/* $font-family-roboto */;
        font-style: normal;
        font-weight: 500;
        @include font-size-20();
        line-height: 23px;
        color: $color-18;
        padding: 3px 0;
        /* margin: 0 2px; */
      }
      padding: 0 2px;
      border: 3px solid transparent;
      &:first-child {
        padding-left: 3px;
      }
      &:last-child {
        padding-right: 3px;
      }
    }
  }
  /* .calendar-content .fc-theme-standard .fc-scrollgrid-section-header th[role="presentation"]{
    padding: 0;
  } */
  .fc-scrollgrid-section {
    td {
      border: none;
    }
  }
  .cells {
    background-color: transparent;
    border: none;
    padding: 3px;
    .fc-daygrid-day-frame {
      border-radius: 5px;
      background-color: $white-color;
      padding: 3px;
    }
    .fc-daygrid-day-top {
      flex-direction: row;
      padding: 0 5px;
      .fc-daygrid-day-number {
        font-family: $font-family-quicksand-title/* $font-family-roboto-slab */;
        font-style: normal;
        font-weight: 800;
        @include font-size-36();
        text-transform: $text-transform-site;
        color: $color-18;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex: 0 0 auto;
        width: 100%;
        padding: 0px;
        gap: 10px;
        .gujarati_date {
          font-family: $font-family-mulish/* $font-family-roboto */;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          text-transform: $text-transform-site;
          color: $black-color-pure;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }
        &:hover {
          text-decoration: none;
          /* color: $black-color-pure; */
          text-shadow: 1px 1px hsl(0deg 3% 50% / 46%);
          .gujarati_date {
            /* color: $dark-active-color; */
          }
        }
      }
    }
    .fc-daygrid-event{
      z-index: unset;
    }
    .fc-daygrid-day-events {
      .fc-daygrid-event-dot {
        display: none;
      }
    }
    .fc-event {
      padding: 3px 5px;
      white-space: break-spaces;
      cursor: pointer;
      .fc-event-main{
        z-index:unset;
      }
      .fc-event-time {
        display: none;
      }
      .fc-event-title {
        font-family: $font-family-mulish/* $font-family-roboto */;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: $black-color-pure;
      }
      &.gujarati{
        .fc-event-title {
          font-family: $font-family-gujarati;
          font-weight: 500;
        }
      }
      @include calendar-tooltip();
    }
  }
  .fc-daygrid-day.fc-day-today {
    background-color: $color-8 !important;
    border: 3px solid #fff;
    .fc-daygrid-day-frame {
      background-color: transparent;
    }
    .fc-daygrid-day-frame {
      &::after {
        /* content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: $color-8;
        z-index: 0;
        border: 3px solid #fff; */
      }
    }
  }
  .fc-col-header-cell-cushion {
    font-family: $font-family-quicksand-title/* $font-family-roboto */;
    font-style: normal;
    font-weight: 600;
    @include font-size-20();
    text-transform: $text-transform-site;
    color: $color-18;
  }
  .fc-daygrid-event-harness {
    margin-bottom: 3px;
  }
}

.fc-event-sidebar-new-title {
  font-family: $font-family-mulish-text/* $font-family-roboto */;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  color: $black-color-pure;
  align-items: center;
  gap: 10px;
}
.fc-event-sidebar {
  padding: 3px 7px;
  white-space: break-spaces;
  cursor: pointer;
  border-radius: 3px;
  display: block;
  position: relative;
  .fc-event-sidebar-main{
    z-index:unset;
  }
  .fc-event-sidebar-time {
    display: none;
  }
  .fc-event-sidebar-title {
    font-family: $font-family-mulish/* $font-family-roboto */;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    color: $black-color-pure;
  }
  &.gujarati{
    .fc-event-sidebar-title {
      font-family: $font-family-gujarati;
      font-weight: 500;
    }
  }
  @include calendar-tooltip();
}

.punam .fc-daygrid-day-frame {
  background-size: 140% 140%;
  background-image: url("../../../public/img/punam.png");
  background-position: center;
}
.amas .fc-daygrid-day-frame {
  background-size: 100% 100%;
  background-image: url("../../../public/icons/punam.svg");
}
.mandir .fc-daygrid-day-frame {
  background-image: url("../../../public/icons/mandir.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 60% auto /* 80% */;
}
.daycellDate {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  &.punam {
    background-size: 160% 160%;
    background-image: url("../../../public/img/punam.png");
    background-position: center;
    border-radius: 50%;
  }
  &.amas {
    background-size: 120% 120%;
    background-image: url("../../../public/icons/punam.svg");
    background-position: center;
    border-radius: 50%;
  }
  &.mandir {
    background-image: url("../../../public/icons/mandir.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }
}
.TypeOfDay {
  position: absolute;
  /* top: 6px;
  right: 0;
  height: 30px;
  width: 30px; */
  left: -1px;
  top: 6px;
  height: 30px;
  width: 30px;
  z-index: -1;
  &.punam {
    background-size: 140% 140%;
    background-image: url("../../../public/img/punam.png");
    background-position: center;
  }
  &.amas {
    background-size: 100% 100%;
    background-image: url("../../../public/icons/punam.svg");
  }
  &.mandir {
    background-image: url("../../../public/icons/mandir.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: 90% auto;
  }
}

@mixin event-style($bg-color: #fffbd6, $border-color: #fdb90a) {
  background: $bg-color !important;
  box-shadow: 0px 0px 2px rgba(255, 199, 159, 0.2) !important;
  border-left: 2px solid $border-color !important;
  border-top-width: 0px !important;
  border-bottom-width: 0px !important;
  border-right-width: 0px !important;
  &:hover {
    .fc-event-title {
      color: $border-color !important;
    }
    background: $bg-color !important;
  }
}

.yellow {
  @include event-style();
}
.deep-sky-blue {
  @include event-style(#e9f6fb, #20b8f9);
}
.psychedelic-purple {
  @include event-style(#fde9ff, #e820f9);
}
.Light-Slate-Blue{
  @include event-style(#eee9fc, #956BF9);
}
.Palatinate-Purple{
  @include event-style(#f7e4f6, #621D60);
}
.Kelly-Green{
  @include event-style(#f0fae8, #59BE11);
}


/*
Calenders ends
*/

/*
Quiz start
*/

@mixin Quiz-option-style($bg-color: #fffbd6, $border-color: #fdb90a) {
  background: $bg-color !important;
  /* border: 1px solid $border-color; */
  border-radius: 5px;
  &:hover {
    opacity: 0.8;
  }
}
.blue-option {
  @include Quiz-option-style(#1b9ceb, #039dfd)
}
.red-option {
  @include Quiz-option-style(#c41e2e, #c41e2e)
}
.orange-option {
  @include Quiz-option-style(#f36e00, #f36e00)
}
.green-option {
  @include Quiz-option-style(#1f7e43, #1f7e43)
}

$particles: 50;
$width: 500;
$height: 500;

// Create the explosion...
$box-shadow: ();
$box-shadow2: ();
@for $i from 0 through $particles {
  $box-shadow: $box-shadow,
               random($width)-(calc($width / 2)) + px
               random($height)-(calc($height / 1.2)) + px
               hsl(random(360), 100%, 50%);
  $box-shadow2: $box-shadow2, 0 0 #fff
}
@mixin keyframes ($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }

    @-moz-keyframes #{$animationName} {
        @content;
    }

    @-o-keyframes #{$animationName} {
        @content;
    }

    @-ms-keyframes #{$animationName} {
        @content;
    }

    @keyframes #{$animationName} {
        @content;
    }
}

@mixin animation-delay ($settings) {
    -moz-animation-delay: $settings;
    -webkit-animation-delay: $settings;
    -o-animation-delay: $settings;
    -ms-animation-delay: $settings;
    animation-delay: $settings;
}

@mixin animation-duration ($settings) {
    -moz-animation-duration: $settings;
    -webkit-animation-duration: $settings;
    -o-animation-duration: $settings;
    -ms-animation-duration: $settings;
    animation-duration: $settings;
}

@mixin animation ($settings) {
    -moz-animation: $settings;
    -webkit-animation: $settings;
    -o-animation: $settings;
    -ms-animation: $settings;
    animation: $settings;
}

@mixin transform ($settings) {
    transform: $settings;
    -moz-transform: $settings;
    -webkit-transform: $settings;
    -o-transform: $settings;
    -ms-transform: $settings;
}

.pyro{
  position: absolute;
  left: 0;
  bottom: 0;
  height: 73%;
  width: 100%;
  
}
.pyro > .before, .pyro > .after {
  position: absolute;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  box-shadow: $box-shadow2;
  @include animation((1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards));
}
    
.pyro > .after {
  @include animation-delay((1.25s, 1.25s, 1.25s));
  @include animation-duration((1.25s, 1.25s, 6.25s));
}
        
@include keyframes(bang) {
  to {
    box-shadow:$box-shadow;
  }
}
    
@include keyframes(gravity)  {
  to {
    @include transform(translateY(200px));
    opacity: 0;
  }
}
    
@include keyframes(position) {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {  
    margin-top: 20%;
    margin-left: 70%
  }
  60%, 79.9% {  
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {  
    margin-top: 30%;
    margin-left: 80%;
  }
}


.OptionQuizMainContainer{
  position: relative;
  overflow: hidden;
  .QuizTimer,.QuizCorrectCount{
    font-weight: 800;
    color: $black-color-pure;
  }
  .nextPrev{
    &.disabled{
      opacity: 0.2;
    }
  }
  .QuizStatus{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $black-color-pure;
    z-index: 9;
    .QuizStatusDetails{
      /* transition: 0.3s all; */
      &.QuizOver{
        left: 0%;
        top: 0%;
        transform: translate(0%, 0%);
        height: 100%;
        width: 100%;
      }
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: $white-color;
      padding: 20px;
      border-radius: 5px;
      color: $active-color;
      @include font-size-20();
      font-weight: 700;
      /* .QuizStatusDetailsTitle{
        scale: 2.3;
      } */
    }
    .QuizComplete{
      position: absolute;
      left: 0%;
      top: 0%;
      height: 100%;
      width: 100%;
      background: $white-color;
    }
  }
  .startQuiz{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #070707df;
    z-index: 3;
    .startQuizBtn{
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background: $active-color;
      padding: 10px;
      border-radius: 5px;
      color: $white-color;
      font-weight: 700;
    }
  }
  .questionContainer{
    .question{
      font-weight: 500;
      @include font-size-38();
      color: $black-color;
    }
    .optionMain{
      min-width: 25%;
      width: unset;
      max-width: 50%;
      @media (max-width: 768px) {
        min-width: 50%;
        max-width: 100%;
      }
    }
    .Options{
      box-shadow: 0px 2px 15px rgba(0,0,0,.3);
      position: relative;
      overflow: hidden;
      &.disabled{
        &::after{
          content: "";
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          background-color: #ffffff98;
          z-index: 8;
        }
      }
      svg,path{
        fill: $white-color;
      }
      .optionName{
        /* color: $grey-color-05-opacity-2; */
        color: $white-color;
        @include font-size-26();
        opacity: 0.5;
        font-weight: 600;
      }
      .optionValue{
        color: $white-color;
        @include font-size-30();
        font-weight: 600;
        line-height: normal;
        word-break: break-word;
      }
      .option-status{
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, 0);
      }
      &:hover{
        background-color: $orange-color-opacity;
        .optionName,.optionValue{
          color: $white-color;
        }
        svg,path{
          fill: $white-color;
        }
      }
    }
  }
}

.custom-scrollbar {
  scrollbar-width: 10px;
  scrollbar-color: $active-color transparent;
}
.custom-scrollbar:-webkit-scrollbar {
  width: 5px;
}
.custom-scrollbar:-webkit-scrollbar-track {
  background: transparent;
}
.custom-scrollbar:-webkit-scrollbar-thumb {
  background-color: $active-color;
  border-radius: 6px;
  border: 3px solid transparent;
}

/* webkit browsers */
.custom-scrollbar::-webkit-scrollbar,
.custom-scrollbar::-webkit-scrollbar-thumb {
  height: 5px;
  border-radius: 0px;
  background-clip: padding-box;
  border: 0px solid transparent;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  height: 15px;
}
.custom-scrollbar::-webkit-scrollbar-thumb {
  box-shadow: inset 0 0 0 10px;
}

/*
Audio player start
*/
.audio_player {
  margin: auto;
  position: fixed;
  bottom: 0;
  width: 100%;
  background: $color-25;
  box-shadow: -5px -4px 15px rgba(0, 0, 0, 0.2);
  padding: 15px;
  .audio-slider {
    position: absolute;
    width: 100%;
    left: 0;
    top: -13px;
    height: auto;
  }
  .MuiSlider-thumb {
    /* transition: 1s all; */
  }
  .MuiSlider-thumb.Mui-focusVisible,
  .MuiSlider-thumb:hover {
    box-shadow: 0px 0px 0px 8px rgb(249 79 32 / 16%);
  }
  .MuiSlider-root {
    color: $color-18;
    padding: 13px 0;
  }
  .audio_player_container {
    width: 100%;

    .title-name {
      font-family: $font-family-quicksand-title/* $font-family-roboto */;
      font-style: normal;
      font-weight: 600;
      @include font-size-22();
      color: $black-color-pure;
      margin: 0;
    }
    .author-name {
      font-family: $font-family-mulish-text/* $font-family-roboto */;
      font-style: normal;
      font-weight: 400;
      @include font-size-18();
      color: $black-color-pure;
      margin: 0;
    }

    .audio-slider {
      display: flex;
      align-items: center;
    }
    .player-different-controls {
      display: flex;
      justify-content: space-between;
      .player-song-thumb {
        display: flex;
        flex-direction: row;
        gap: 10px;
        .player-thumb-img {
          width: 60px;
          height: auto;
          img {
            width: 100%;
            border-radius: 8px;
          }
        }
        .player-thumb-details {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 0px;
        }
      }
      .player-controls {
        margin-left: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 25px;
        flex-direction: row;
        .play-pause-button {
          background-color: $color-18;
          width: 50px;
          height: 50px;
          border-radius: 50%;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          box-shadow: 0 3px 3px 0 rgb(0 0 0 / 24%), 0 0 3px 0 rgb(0 0 0 / 12%);
        }
        .time-show {
          display: flex;
          padding: 5px 15px;
          height: 40px;
          align-items: center;
          border: 2px solid $color-18;
          border-radius: 30px;
          gap: 5px;
          font-family: $font-family-mulish-text/* $font-family-roboto */;
          font-style: normal;
          font-weight: 500;
          @include font-size-18();
          color: $black-color-pure;
          justify-content: space-between;
          margin-left: 20px;
          p {
            margin: 0;
          }
        }
      }
      .player-volume {
        gap: 15px;
        margin-right: /* 55px */ 0px;
        .arrow-icon {
        }
      }
      @media (max-width: 700px) {
        .player-controls {
          .time-show,
          .repeat-icon {
            display: none;
          }
        }
        .player-volume {
          .MuiGrid-container {
            justify-content: flex-end;
            .MuiGrid-item {
              display: none;
            }
          }
        }
      }
      @media (max-width: 500px) {
        .player-song-thumb {
          .player-thumb-details {
            .author-name {
              display: none;
            }
          }
        }
        .player-controls {
          margin-left: 0px;
          gap: 10px;
        }
      }
    }
  }
}
.play-pause-button {
  @include play-pause-button();
  width: 35px;
  height: 35px;
  svg {
    width: 20px;
  }
}
.music-container.react-jinke-music-player-main ::-webkit-scrollbar-thumb {
  background-color: #9e9e9e;
}
.music-container.react-jinke-music-player-main {
  font-family: $font-family-mulish-text/* $font-family-roboto */;
  .audio-lists-panel-header-line {
    background: #323232;
  }

  svg {
    fill: #323232;
  }
  .play-pause-button {
    @include play-pause-button();
  }
  .react-jinke-music-player-mobile-header-title {
    font-family: $font-family-quicksand-title/* $font-family-roboto */;
    font-style: normal;
    font-weight: 600;
    @include font-size-22();
    color: $black-color-pure;
    margin: 0;
  }
  .react-jinke-music-player-mobile-singer-name {
    font-family: $font-family-mulish-text/* $font-family-roboto */;
    font-style: normal;
    font-weight: 500;
    @include font-size-18();
    color: $black-color-pure;
  }
  .react-jinke-music-player-mobile-cover {
    border-radius: 15px;
    box-shadow: none;
    overflow: auto;
    border: none;
  }
  .react-jinke-music-player-mobile {
    opacity: 1;
  }
  .react-jinke-music-player-mobile-progress {
    .current-time,
    .duration {
      font-family: $font-family-mulish/* $font-family-roboto */;
      font-style: normal;
      font-weight: 500;
      @include font-size-18();
      color: $black-color-pure;
    }
  }
  .music-player-panel {
    border-top: 2px solid $dark-active-color;
    box-shadow: -5px -4px 15px rgba(0, 0, 0, 0.2);
    background-color: $top-bar-color-light;
    .panel-content {
      .progress-bar-content {
        display: flex;
        flex-direction: column;
        gap: 5px;
        justify-content: space-between;
        .audio-main {
          margin: 0;
          .current-time,
          .duration {
            font-family: $font-family-mulish/* $font-family-roboto */;
            font-style: normal;
            font-weight: 500;
            @include font-size-16();
            color: $black-color-pure;
          }
        }
        .audio-title {
          font-family: $font-family-quicksand-title/* $font-family-roboto */;
          font-style: normal;
          font-weight: 700;
          @include font-size-22();
          color: $black-color-pure;
          margin: 0;
        }
      }
      .img-content {
        background-size: 100% 100%;
        border-radius: 10px;
        animation: none;
      }
      .player-content {
        .audio-lists-btn {
          background-color: $color-18;
          color: $white-color;
          svg {
            fill: $white-color;
          }
        }
      }
    }
  }
  .react-jinke-music-player-mobile > .group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    .items {
      gap: 15px;
    }
  }
  .react-jinke-music-player-mobile-cover {
    .cover {
      animation: none;
      border-radius: 15px;
    }
  }
  .audio-lists-panel {
    border: 2px solid $dark-active-color;
    border-bottom: transparent;
    margin-bottom: -2px;
    box-shadow: -5px -13px 15px rgb(0 0 0 / 20%);
    color: $black-color-pure;
    background-color: $top-bar-color-light;
    .audio-lists-panel-content {
      .audio-item {
        &:nth-child(odd) {
          background-color: transparent;
        }
        &.playing,
        &:hover {
          background-color: $color-18 !important;
          color: $white-color;
          .player-icons {
            .play-pause-button {
              svg {
                fill: $white-color;
              }
            }
          }
          svg {
            fill: $white-color;
          }
        }
        .player-icons {
          .play-pause-button {
            background-color: transparent;
            box-shadow: none;
            height: auto;
            width: auto;
            svg {
              fill: #323232;
            }
          }
        }
      }
    }
  }
}

/*
Audio player ends
*/

/* Audio manager start*/
.player-controls {
  margin-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 25px;
  flex-direction: row;
  .play-pause-button {
    background-color: $color-18;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    box-shadow: 0 3px 3px 0 rgb(0 0 0 / 24%), 0 0 3px 0 rgb(0 0 0 / 12%);
  }
  .time-show {
    display: flex;
    padding: 5px 15px;
    height: 40px;
    align-items: center;
    border: 2px solid $color-18;
    border-radius: 30px;
    gap: 5px;
    font-family: $font-family-mulish/* $font-family-roboto */;
    font-style: normal;
    font-weight: 500;
    @include font-size-18();
    color: $black-color-pure;
    justify-content: space-between;
    margin-left: 20px;
    p {
      margin: 0;
    }
  }
}

@media (max-width: 500px) {
  .player-controls {
    gap: 18px;
  }
}

.manager {
  background-color: $color-15;
  .audio-manager-container {
    background: $white-color;
    border: 1px solid $color-20;
    border-radius: 15px;
    position: relative;
    .audio-manager-container-inner {
      padding: 35px;
    }
  }
  .manager-container {
    border-radius: 8px;
    padding: 20px;
    gap: 10px;
    background: $color-25;
    border: 2px solid $color-18;
    .container-title {
      @include font-size-22();
      font-weight: 500;
      text-align: center;
      text-transform: capitalize;
      color: $color-18;
    }
    .container-subtitle {
      @include font-size-18();
      font-weight: 500;
      color: $black-color-pure;
      text-align: center;
      text-transform: capitalize;
    }
    .image-container {
      img {
        width: 100%;
        border-radius: 10px;
      }
    }
    .timer {
      p {
        margin: 0;
        gap: 5px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        @include font-size-17();
        color: $black-color-pure;
        align-items: center;
        display: flex;
        flex-direction: row;
      }
      display: flex;
      justify-content: space-between;
    }
    .manager-different-controls {
      display: flex;
      justify-content: space-between;
      .manager-volume {
        width: 140px;
      }
    }
    .MuiSlider-root {
      color: $color-18 !important;
    }
  }

  .list_container {
    display: flex;
    justify-content: space-between;
    position: relative;
    padding-bottom: 15px;
    align-items: center;
    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 1px;
      border-bottom: 2px dashed $color-18;
    }
    h4 {
      font-family: $font-family-quicksand-title/* $font-family-roboto */;
      font-style: normal;
      font-weight: 500;
      text-transform: $text-transform-site;
      color: $color-18;
      @include font-size-36();
      line-height: 1;
    }
    .buttons {
      display: flex;
      gap: 10px;
      align-items: center;
      .list_button {
        border: none;
        outline: none;
        background-color: rgb(169, 90, 90);
        padding: 5px 15px;
        font-size: 18px;
        border-radius: 25px;
      }
    }
  }
  .list-names {
    gap: 15px;
    .list {
      border-bottom: 1px solid #ffffff;
      display: flex;
      justify-content: space-between;
      .track-number {
        font-family: $font-family-mulish/* $font-family-roboto */;
        color: $black-color-pure;
        @include font-size-18();
        font-weight: 500;
      }
      .text-duration {
        font-family: $font-family-mulish/* $font-family-roboto */;
        color: $black-color-pure;
        @include font-size-16();
        font-weight: 500;
      }
      div {
        display: flex;
        width: 100%;
        gap: 10px;
        align-items: center;
        img {
          width: 50px;
          height: 50px;
          border-radius: 5px;
        }
        .list_details {
          flex-direction: column;
          gap: 5px;
          align-items: flex-start;
          .title-name {
            font-family: $font-family-quicksand-title/* $font-family-roboto */;
            color: $black-color-pure;
            @include font-size-18();
            width: 200px;
          }
          .author-name {
            font-family: $font-family-mulish-text/* $font-family-roboto */;
            color: $black-color-pure;
            @include font-size-18();
            width: 200px;
          }
          .title-name.active,
          .author-name.active {
            color: $dark-active-color;
          }
        }
      }
      .remove-items {
        justify-content: flex-end;
        cursor: pointer;
        .cross-icon {
          display: none;
          margin-right: 10px;
          font-family: $font-family-mulish-text/* $font-family-roboto */;
          color: $black-color-pure;
          @include font-size-18();
          font-weight: 600;
          &:hover {
            color: $color-18;
          }
        }
      }
    }
    .list:hover {
      .remove-items {
        .cross-icon {
          display: block;
        }
      }
    }
  }
  .list-names:hover {
    /* overflow-y: auto; */
  }
  .list-names::-webkit-scrollbar {
    width: 8px;
  }

  .list-names::-webkit-scrollbar-track {
    margin-top: 40px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 5px;
  }

  .list-names::-webkit-scrollbar-thumb {
    border-radius: 5px;
    -webkit-box-shadow: inset 0 0 6px #fff;
  }
}

.found-in-search {
  .found-in-search-title {
    margin: 0;
    color: $dark-active-color;
    font-family: $font-family-quicksand-title/* $font-family-roboto */;
    font-weight: 500;
    @include font-size-18();
  }
  .found-in-search-text {
    color: $black-color-pure;
    gap: 5px;
    @include font-size-16();
    font-family: $font-family-mulish-text/* $font-family-roboto */;
    .dot {
      font-size: 20px;
      line-height: 0.9;
    }
  }
}

.cms-text-desc {
  &.left {
    &.auto {
      .image {
        float: left;
      }
    }
  }
  &.right {
    &.auto {
      .image {
        float: right;
      }
    }
  }
}
.live-darshan-section {
  /* gap: 10px; */
}
@media (max-width: 992px) {
  .manager {
    height: auto;
    .manager-container {
      width: 100%;
      box-shadow: none;
    }
    .list_container {
      margin-left: 20px;
    }
    .list-names {
      .list {
        margin-left: 20px;
      }
    }
  }
}
@media (max-width: 500px) {
  .manager {
    .manager-different-controls {
      .manager-controls {
        margin: auto;
        .manager-controls-icon {
          margin: 0 5px;
        }
      }
      .manager-volume {
        display: none;
      }
    }
  }
  .manager {
    .audio-manager-container {
      .audio-manager-container-inner {
        padding: 0 0 15px 0;
      }
    }
  }
  .manager {
    .manager-container {
      .MuiSlider-root {
        padding: 12px 0;
      }
    }
  }
}

/* Audio manager ends */

@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}
@media (max-width: 992px) {
  #header {
    /* padding: 7px 0px;
    top: 40px; */
    .logo {
      font-size: 28px;
    }
  }
  #header.header-scrolled {
    /* padding: 7px 0px; */
  }
  #hero {
    .container {
      padding-top: 58px;
    }
  }
  .breadcrumbs {
    margin-top: 58px;
    h2 {
      margin: 0 0 10px 0;
    }
  }
  body {
    padding: 60px 0 0 0;
  }
  #enquireModal {
    .modal-content {
      padding: 0;
    }
  }
  .key-facts-img {
    width: 35px;
    height: 35px;
  }
  .tour-details-section {
    .tour-menu {
      justify-content: start !important;
      flex-wrap: nowrap !important;
      overflow-x: auto;
      width: 100%;
      .key-facts-inner {
        width: auto;
        min-width: auto;
        padding: 15px 10px;
      }
      .key-facts-title {
        white-space: nowrap;
      }
    }
    .tour-menu-section {
      top: 56px;
    }
  }
  .publication-details-container {
    .publication-image {
      margin-top: 0px;
    }
  }
  /* .publication-contain {
    .publications {
      .publication {
        .publication-icon{
          left: 34%;
        }
      }
    }
  } */
}

@media (max-width: 991px) {
  .publication-contain {
    .publications {
      .publication {
        .publication-title {
          white-space: nowrap;
        }
      }
    }
  }
}

@media (max-width: 1800px) {
  #header .container,
  #topbar .container {
    /* width: 72%; */
  }
}
@media (max-width: 1700px) {
  #header .container,
  #topbar .container {
    /* width: 80%; */
  }
}
@media (max-width: 1600px) {
  .header-pr {
    padding-right: 40px !important;
  }
  .header-pl {
    padding-left: 40px !important;
  }
  #topbar .contact-info a,
  #topbar .contact-info .sub-menu-nav,
  .nav-menu a,
  .nav-menu .sub-menu-nav{
    font-size: 14px;
  }
  .bottom-right-design,
  .top-left-design {
    height: 120px;
    width: 120px;
    background-size: 100%;
  }

  .calendar-content {
    .cells {
      .fc-daygrid-day-top {
        .fc-daygrid-day-number {
          flex-direction: column;
          gap: 0px;
          .gujarati_date {
            /* text-transform: capitalize; */
          }
        }
      }
    }
  }
}
@media (max-width: 1366px) {
  .nav-menu {
    .drop-down {
      .drop-down {
        ul {
          left: -90%;
        }
        &:hover {
          > ul {
            left: -100%;
          }
        }
        > a {
          &:after {
            content: "\ea9d";
          }
        }
      }
    }
  }

  /* fontsize and spacing */
  .header-pr {
    padding-right: 40px !important;
  }
  .header-pl {
    padding-left: 40px !important;
  }
  #topbar .contact-info a,
  #topbar .contact-info .sub-menu-nav,
  .nav-menu a{
    font-size: 13px;
  }
  #footer .footer-top .description,
  #footer .footer-top h4,
  .footer-newsletter-form input[type="email"] {
    font-size: 14px;
  }

  // spaces
  .aboutus .aboutus-box {
    padding: 45px 20px 45px 20px;
  }
  #footer .Bottom_Flower {
    bottom: 68%;
    width: 340px;
  }
}
@media (max-width: 1300px) {
  /* fontsize and spacing */
  .header-pr {
    padding-right: 30px !important;
  }
  .header-pl {
    padding-left: 30px !important;
  }
}
@media (max-width: 1200px) {
  .live-darshan .live-darshan-box {
    padding: 35px 30px;
  }
  .live-darshan .live-darshan-box .title {
    padding: 25px 0 30px;
  }
  .footer-newsletter-form input[type="email"] {
    width: calc(100% - 90px);
  }
  .aboutus .aboutus-box {
    padding: 35px 15px 35px 15px;
  }
  .guruparampara .guruparampara-box .guruparampara-text {
    margin: 32px 0 0 0;
  }
  .publication-contain .publications .publication .publication-data {
    padding: 30px 30px 30px 120px;
  }
  /* font size */
  #footer .footer-bottom {
    font-size: 12px;
  }
  #footer .footer-top .description,
  #footer .footer-top h4,
  .footer-newsletter-form input[type="email"],
  .footer-newsletter-form input[type="submit"] {
    font-size: 12px;
  }
  .publication-contain .publications .publication .publication-icon {
    left: 34%;
  }
}
@media (max-width: 1024px) {
  .aboutus {
    padding: 100px 0;
  }
  .calendar-content {
    .cells {
      .fc-daygrid-day-top {
        padding: 0;
        .fc-daygrid-day-number {
          flex-direction: column;
          font-size: 20px;
          .gujarati_date {
            text-transform: capitalize;
          }
        }
      }
    }
  }
  .image-text-row {
    .image-text-row-text {
      margin-top: 0;
    }
  }
}
@media (min-width: 1024px) {
  #hero {
    background-attachment: fixed;
  }
  .section-title {
    p {
      width: 50%;
    }
  }
  .testimonials {
    background-attachment: fixed;
  }
  .aboutus {
    padding: 80px 0;
  }
}
@media (max-width: 991px) {
  #header {
    padding: 8px 0;
  }
  .publication-contain {
    .publications {
      .publication {
        .publication-icon {
          height: 130px;
          width: 130px;
          top: 70px;
          transform: translate(-50%, 0%);
          left: 50%;
        }
        .publication-inner {
          min-height: auto;
        }
        .publication-title {
          padding: 20px 20px 80px;
        }
        .publication-data {
          padding: 80px 20px 20px;
          border: 1px solid $color-26;
          border-radius: 0 0 10px 10px;
          border-top: none;
          .publication-data-inner {
            justify-content: center;
          }
        }
      }
    }
  }
  .publication-details-container {
    .publication-details-container-inner {
      padding: 30px;
    }
    .publication-details-title {
      margin-top: 15px;
    }
  }
  .news-image-text .they-say-text{
    width: 100%;
    bottom: 0;
  }
  .news-image-text .they-say-text .they-say-sub-title{
    padding: 10px 13px;
  }
}
@media (max-width: 768px) {
  .cms-text-desc {
    &.left {
      &.auto {
        .image {
          float: none;
        }
      }
    }
    &.right {
      &.auto {
        .image {
          float: none;
        }
      }
    }
  }
  .publication-details-related {
    .related-data {
      padding: 15px;
    }
  }

  
  .publication-contain {
    .publications {
      .publication {
        .publication-title{
          padding-bottom: 60px;
        }
        .publication-icon{
          height: 90px;
          width: 90px;
          img{
            width: 90%;
          }
        }
        .publication-data {
          padding-top: 60px;
          .publication-data-inner {
            justify-content: center;
            gap: 10px 10px;
            .btn-2{
              min-width: 90px;
              padding: 5px 15px;
            }
          }
        }
      }
    }
  }
  .form-control,
  select.form-control {
    padding: 12px 30px 12px 15px;
    background-size: 15px;
  }
  .guruparampara {
    .guruparampara-box {
      margin: 30px 0 0 0;
    }
  }
  .publication-details-container {
    .publication-details-container-inner {
      padding: 15px;
    }
  }
  #hero {
    height: 25vh;
    h1 {
      font-size: 28px;
    }
    h2 {
      /* font-size: 24px; */
      line-height: normal;
    }
    .banner-price {
      font-size: 15px;
      line-height: normal;
    }
    .banner-btn {
      margin-top: 5px;
    }
  }
  .breadcrumbs {
    .d-flex {
      display: block !important;
    }
    ol {
      display: block;
      li {
        display: inline-block;
      }
    }
  }
  .clients {
    img {
      max-width: 40%;
    }
  }
  .portfolio-details {
    .portfolio-description {
      h2 {
        width: 100%;
      }
    }
    .portfolio-info {
      position: static;
      margin-top: 30px;
    }
  }

  section {
    padding: 20px 0;
  }
  .content {
    h3 {
      font-size: 22px;
    }
  }
  .description {
    font-size: 13px;
  }
  ul.ul-list {
    li {
      font-size: 13px;
    }
  }
  .best-seller {
    padding-bottom: 60px;
  }
  .section-bottom {
    padding-bottom: 60px;
  }
  .key-facts-inner {
    width: 150px;
  }
  .our-product {
    margin-top: 0;
  }
  .confirmation-details-section {
    background-image: none;
    background-image: none;
  }
  .career-section {
    background-image: none;
  }
  .privacy-details-section {
    background-image: none;
  }
  .file-control-attach {
    margin: 10px 0 0 0;
    display: block;
    width: 50%;
    float: right;
  }
  .file-control {
    .file-control-icon {
      top: 7px;
      transform: translate(0px, 0%);
    }
  }
  .aboutus {
    padding: 90px 0;
  }
  .aboutus .aboutus-box {
    margin: 30px 0 0 0;
    height: calc(100% - 30px);
  }
  .global-network-box-o {
    height: 270px;
  }
  .banner_slider .bannerDots {
    bottom: 10px;
    transform: translate(-50%, 0%);
  }

  #footer {
    .follow-us {
      gap: 10px;
      align-items: center;
      justify-content: start !important;
      .footer-social {
        background-color: $active-color;
        height: 25px;
        width: 25px;
        border-radius: 5px;
        svg{
          height: 1.3em;
        }
        i {
          font-size: 20px;
        }
      }
    }
  }
}
@media (max-width: 575px) {
  #hero {
    height: 50vh !important;
    padding-bottom: unset !important;
  }
  .curve-effect-bottom {
    display: none;
  }
  .banner-sub-title,
  .banner-title {
    /* max-width: 80%;
    text-align: center;
    min-width: 70%; */
    padding: 7px 15px;
  }
  .guruparampara .guruparampara-box .guruparampara-text {
    margin: 10px 0 0 0;
  }
  .about-us-blocks {
    flex-wrap: nowrap !important;
    overflow-x: auto;
    padding-bottom: 15px;
  }
  .live-darshan-section {
    flex-wrap: nowrap !important;
    overflow-x: auto;
    gap: 0px;
    margin: 0;
    &.row > * {
      padding-right: 2px;
      padding-left: 2px;
    }
  }
  .live-darshan {
    padding-top: 0px !important;
  }
  .live-darshan .container {
    width: 100%;
    padding: 0;
  }
  .live-darshan .live-darshan-box-container {
    border-radius: 0px;
    background: transparent;
    box-shadow: none;
    flex-wrap: nowrap !important;
    overflow-x: auto;
  }
  .live-darshan .live-darshan-box {
    border-radius: 15px;
    background: $white-color;
    box-shadow: 0px 4px 80px 20px rgba(0, 0, 0, 0.08);
    margin: 20px 0 20px 20px;
    &:last-child {
      margin-right: 20px;
    }
  }
  .live-darshan .live-darshan-box img,
  .aboutus .aboutus-box img {
    width: 70px;
  }
  .live-darshan .live-darshan-box .title {
    padding: 20px 0 25px;
  }
  .live-darshan .live-darshan-box .sub-title {
    margin: 15px 0 5px 0;
  }
  .aboutus .aboutus-box img {
    padding: 10px 0 10px;
  }
  .aboutus .aboutus-box .title {
    margin: 0 0 20px 0;
    padding: 10px 0 10px 0;
  }
  .aboutus .aboutus-box {
    margin: 20px 0 0 0;
    height: calc(100% - 20px);
  }
  .aboutus {
    padding: 45px 0;
  }
  .banner_slider .bannerDots {
    bottom: 3px;
  }
  .banner_slider .bannerDots .owl-dot span {
    height: 7px;
    width: 7px;
  }

  .banner_slider {
    .carousel {
      .slick-dots {
        bottom: 1px;
      }
    }
  }

  /* .guruparampara {
    .guruparampara-box {
      .guruparampara-image {
        width: 70%;
      }
    }
  } */

  .bottom-right-design,
  .top-left-design {
    width: 110px;
    height: 110px;
  }
  .top-left-bg,
  .bottom-right-bg {
    width: 100%;
  }
  .white-bg-with-borders .content {
    padding: 15px;
  }
  .no-bg-with-design-2 .content,
  .white-bg-with-design-and-shadow-quote .content {
    padding: 35px;
  }
  .white-bg-with-design-and-shadow-quote .cms-text-desc {
    padding: 15px 20px;
  }
  .no-bg-with-design-2 .content .cms-text-desc .cms-text::after,
  .white-bg-with-design-and-shadow-quote
    .content
    .cms-text-desc
    .cms-text::after {
    top: -4px;
  }
  .publication-data-container{
    .publication-data {
      padding: 10px;
    }
  }
}
@media (max-height: 500px) {
  #hero {
    height: 120vh;
  }
  .global-network-box-o {
    height: 270px;
  }
}
@media (max-width: 500px) {
  .global-network-box-o {
    height: 150px;
  }
  .publication-details-sidebar-contain {
    .publication-details-sidebar {
      width: 80%;
    }
  }
  .manager .list_container,
  .manager .list-names .list {
    margin-right: 20px;
  }
  .manager .list-names .list div .list_details .title-name,
  .manager .list-names .list div .list_details .author-name {
    width: 40vw;
  }
  .manager .audio-manager-container {
    background: transparent;
    border: none;
  }
}
@media (min-width: 992px) {
  .testimonials {
    .testimonial-item {
      p {
        width: 80%;
      }
    }
  }
}
@media (max-width: 1600px) {
  .container {
    width: 98%;
  }
  .container-lg {
    width: 98%;
  }
  .container-md {
    width: 98%;
  }
  .container-sm {
    width: 98%;
  }
  .container-xl {
    width: 98%;
  }
}
@media (max-width: 999px) {
  .best-seller-carousel.owl-theme {
    .owl-dots {
      left: 51%;
    }
    .owl-nav {
      left: 51%;
    }
  }
  #topbar .container,
  #header .container {
    width: 90%;
  }
  .live-darshan {
    .live-darshan-box {
      padding: 30px 30px;
      position: relative;
    }
  }
  .grid--masonry {
    grid-template-columns: repeat(2, auto);
  }
  .review-block {
    .grid-item {
      margin-bottom: 30px;
    }
  }
  .Bottom_Flower {
    display: none;
  }
  #footer {
    padding: 0;
    .copyright {
      float: none;
      text-align: center;
      padding: 2px 0;
    }
    .credits {
      float: none;
      text-align: center;
      padding: 2px 0;
    }
    .footer-top {
      padding: 40px 0 10px 0;
      background: $orange-linear;
    }
    .footer-newsletter {
      form {
        input[type="email"] {
          padding: 8px 12px;
          width: calc(100% - 110px);
        }
        input[type="submit"] {
          font-size: 14px;
        }
      }
      h4 {
        font-size: 22px;
      }
    }
  }
}
@media (max-width: 599px) {
  .best-seller-carousel.owl-theme {
    .owl-dots {
      position: relative;
      left: auto;
      right: auto;
      text-align: center;
      padding: 15px 5px 5px 5px;
      height: auto;
      background: transparent;
    }
    .owl-nav {
      position: relative;
      left: auto;
      right: auto;
      text-align: center;
      padding: 15px 5px 5px 5px;
      height: auto;
      background: transparent;
    }
  }
  .grid--masonry {
    grid-template-columns: repeat(1, auto);
    .masonry-item {
      height: auto !important;
    }
  }
  ul.ul-list {
    padding: 0 0 0 30px;
  }
  #itinerary {
    .toggle-accordion {
      padding: 5px 10px;
    }
    .title {
      text-align: left !important;
    }
  }
  .btn-primary {
    padding: 10px 20px;
  }
  .career-highlight-name {
    width: 100%;
    font-size: 14px;
    padding: 5px 10px;
  }
}
@media (min-width: 1600px) {
  .container {
    max-width: 1600px;
  }
  .container-lg {
    max-width: 1600px;
  }
  .container-md {
    max-width: 1600px;
  }
  .container-sm {
    max-width: 1600px;
  }
  .container-xl {
    max-width: 1600px;
  }
}
