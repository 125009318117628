body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.music-container.react-jinke-music-player-main .react-jinke-music-player {
  z-index: 9999;
}
.music-container.react-jinke-music-player-main
  .react-jinke-music-player
  .music-player-controller
  .music-player-controller-setting {
  background-color: hsl(
    calc(var(--hue) - 3),
    calc(var(--saturation)),
    calc(var(--light) + 45%)
  );
}
.music-container.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-main
  .current-time,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-main
  .duration,
.music-container.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-title {
  color: black;
}
.music-container.react-jinke-music-player-main .music-player-panel {
  background: hsl(
    calc(var(--hue) - 3),
    calc(var(--saturation)),
    calc(var(--light) + 45%)
  );
  box-shadow: -5px -4px 15px rgb(0 0 0 / 20%);
}
/* .music-container.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .play-btn
  svg {
  fill: hsl(
    calc(var(--hue) - 16),
    calc(var(--saturation) - 5%),
    calc(var(--light) + 5%)
  );
} */

.music-container.react-jinke-music-player-main .rc-slider-rail {
  background-color: hsl(
    calc(var(--hue) - 16),
    calc(var(--saturation) - 5%),
    calc(var(--light) + 25%)
  ) !important;
}
.music-container.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-track {
  background-color: hsl(
    calc(var(--hue) - 16),
    calc(var(--saturation) - 5%),
    calc(var(--light) + 7%)
  ) !important;
}
.music-container.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-handle,
.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .rc-slider-track,
.music-container .react-jinke-music-player-mobile-progress .rc-slider-handle,
.music-container .react-jinke-music-player-mobile-progress .rc-slider-track {
  background: hsl(
    calc(var(--hue) - 16),
    calc(var(--saturation) - 5%),
    calc(var(--light) + 7%)
  );
}
.music-container.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar {
  padding: 0 10px;
  margin: 0;
  background: transparent;
}
.music-container.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .audio-main {
  padding: 0;
}
.music-container.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .progress-bar-content
  .progress-bar
  .progress-load-bar {
  display: none;
}
.music-container.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .img-content {
  background-size: 100% 100%;
}
.music-container.react-jinke-music-player-main .audio-lists-panel {
  color: black;
  background: hsl(
    calc(var(--hue) - 3),
    calc(var(--saturation)),
    calc(var(--light) + 45%)
  );
  box-shadow: -5px -4px 15px rgb(0 0 0 / 20%);
}
.music-container.react-jinke-music-player-main
  .audio-lists-panel-content
  .audio-item.playing {
  color: #fff;
}
.music-container.react-jinke-music-player-main
  .music-player-panel
  .panel-content
  .player-content
  .audio-lists-btn {
  color: black;
}
@media (max-width: 900px) {
  .music-container .react-jinke-music-player-mobile-cover .cover {
    height: 100%;
    background-size: 100% 100%;
  }
  .music-container .react-jinke-music-player-mobile {
    top: 65px;
    background-color: #fff;
    opacity: 0.9;
    color: #333;
  }
  .music-container .audio-lists-panel-mobile {
    top: 65px;
  }
}
@media (max-width: 767px) {
  .music-container.react-jinke-music-player-main
    .music-player-panel
    .panel-content
    .progress-bar-content {
    display: block !important;
  }
}
